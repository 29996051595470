import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { NavParams, PopoverController, NavController } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';

@Component({
  selector: 'app-popover_torlodazusert',
  templateUrl: './popover_torlodazusert.component.html',
  styleUrls: ['./popover_torlodazusert.component.scss'],
})

export class PopoverTorlodazusertComponent implements OnInit {
  /*
    @ViewChild('ido_nyitas') _ido_nyitas;
    @ViewChild('ido_zaras') _ido_zaras;
  */
  idoszelektorform: FormGroup;
  popoverCtrl: PopoverController;
  navParams: NavParams;
  id: string;
  settings: SettingsService;


  felirat_popover: string;
  felirat_megsem: string;
  felirat_torol: string;
  type: any;


  //exp_nyitas: string;
  //exp_zaras: string;
  //idoszelektor: String;
  constructor(private fb: FormBuilder, public _settings: SettingsService, public params: NavParams, private http: HTTP, public navCtrl: NavController) {
    //var idoszelektor = document.getElementById('idoszelektor');
    this.settings = _settings;
    this.navParams = params;
    this.popoverCtrl = params.get('popoverCtrl');
    this.id = params.get('id');

    this.felirat_popover = params.get('felirat_popover');
    this.felirat_megsem = params.get('felirat_megsem');
    this.felirat_torol = params.get('felirat_torol');
    this.type = params.get('type');

    console.log("this.type: " + this.type);
  }
  click_megsem() {
    this.popoverCtrl.dismiss();
  }

  click_torol() {
    var valasz: string | { [x: string]: string; }[];
    //var navCtrl: NavController;
    //var settings: SettingsService;

    if (this.type == 'foglalas') {
      let postData = {
        "id": this.id,
        "uid": 0
      }      
      this.http.setDataSerializer('json');
      console.log(this.settings.serviceurl + 'calendar/torolfoglalas');
      console.log(postData);
      this.http.post(this.settings.serviceurl + 'calendar/torolfoglalas', postData, this.settings.httpauthheader())
        .then(data => {
          console.log(data.data);
          //valasz = JSON.parse(data.data);
          //this.settings.token=valasz["token"];
          //this.popoverCtrl.dismiss();
          //this.navCtrl.navigateForward('tab_szolgaltato_naptar');
          //this.ngOnInit();
          //this.ngOnInit();
        }).catch(error => {

          //valasz = JSON.parse(error.error);
          console.log("valasz" + valasz);
          console.log(error.status);
          console.log(error.error); // error message as string
          console.log(error.headers);
        });
    } else if (this.type == 'delkontakt') { 

      let postData = {
        "id": this.id
      }      
      this.http.setDataSerializer('json');
      console.log(this.settings.serviceurl + 'company/delkontakt');
      console.log(postData);
      this.http.post(this.settings.serviceurl + 'company/delkontakt', postData, this.settings.httpauthheader())
        .then(data => {
          console.log(data.data);
          //valasz = JSON.parse(data.data);
          //this.settings.token=valasz["token"];
          //this.popoverCtrl.dismiss();
          //this.navCtrl.navigateForward('tab_szolgaltato_naptar');
          //this.ngOnInit();
          //this.ngOnInit();
        }).catch(error => {

          //valasz = JSON.parse(error.error);
          console.log("valasz" + valasz);
          console.log(error.status);
          console.log(error.error); // error message as string
          console.log(error.headers);
        });      
    }

    this.popoverCtrl.dismiss();
  }

  ngOnInit() {
  }
}
