import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { NavParams, PopoverController, NavController } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';

@Component({
    selector: 'app-popover_useradatmodositas',
    templateUrl: './popover_useradatmodositas.component.html',
    styleUrls: ['./popover_useradatmodositas.component.scss'],
})

export class PopoverUseradatmodositasComponent implements OnInit {

    //@ViewChild('searchbar')
    //searchbar: AutoCompleteComponent;

    form_kontaktok_egyuser: FormGroup;


    popoverCtrl: PopoverController;
    navParams: NavParams;
    id: string;
    settings: SettingsService;
    serviceurl: string = '';

    hiba_name: string = '';
    hiba_phone: string = '';
    hiba_email: string = '';

    name: String = '';
    phone: String = '';
    email: String = '';
    //service_id: string = '';


    utolsovalasztottname: any = '';
    megjelenhetautocomp: any = false;

    input: string = '';

    sikeresfoglalas: number = 0;

    sorok: any = [];
    //_sorok: string = '{"name":"sadfasdfas","phone":"1545154545454","email":"lklklokd@aaaas.hu","elojegyzesek":[{"szolgneve":"Hajvagas","idopont":"2019-01-01","valasztottszin":"9a7d0a"}]}';

    naplo_valasztottszolgaltatas_idopont_datum: any;

    //exp_nyitas: string;
    //exp_zaras: string;
    //idoszelektor: String;
    constructor(private fb: FormBuilder, public navCtrl: NavController, public _settings: SettingsService, public params: NavParams, private http: HTTP) {
        this.serviceurl = _settings.serviceurl;


        this.form_kontaktok_egyuser = new FormGroup({
            email: new FormControl(),
            phone: new FormControl(),
            name: new FormControl(),
            kontakthozad: new FormControl(),
        });



        //var idoszelektor = document.getElementById('idoszelektor');
        this.settings = _settings;
        this.naplo_valasztottszolgaltatas_idopont_datum = this.settings.naplo_valasztottszolgaltatas_idopont_datum;
        this.navParams = params;
        this.popoverCtrl = params.get('popoverCtrl');
        this.id = params.get('id');
        if (params.get('service_id') !== '') {
            this.settings.naplo_valasztottszolgaltatas_szolgid = params.get('service_id');
            //console.log("A SERVICEID:");
            //console.log(this.service_id);
            this.get_serviceparams();
        }




        //this.sorok = JSON.parse(this._sorok);
        //console.log("A TOMB:");
        //console.log(this.sorok.email);

        this.get_form_kontaktok_egyuser();



        console.log('this.id: ' + this.id);
    }

    click_megsem() {
        this.popoverCtrl.dismiss();
    }

    kivalaszt() {
        console.log('valasztott');
        /*
            this.form_kontaktok_egyuser = this.fb.group({
              email: this.searchbar.selected[0]["email"],
              phone: this.searchbar.selected[0]["phone"],
              name: this.searchbar.selected[0]["name"],
            })

            this.id = this.searchbar.selected[0]["id"];
        */
        //console.log(this.searchbar.selected[0]["id"]);
    }

    datummegjelenit() {
        var out: string = '';
        if (this.settings.naplo_valasztottszolgaltatas_idopont_datum != null) {
            out += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(0, 4) + ' ';
            if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '01') {
                out += 'Január';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '02') {
                out += 'Február';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '03') {
                out += 'Március';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '04') {
                out += 'Április';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '05') {
                out += 'Május';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '06') {
                out += 'Június';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '07') {
                out += 'Július';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '08') {
                out += 'Augusztus';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '09') {
                out += 'Szeptember';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '10') {
                out += 'Október';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '11') {
                out += 'November';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '12') {
                out += 'December';
            }
            out += ' ';
            out += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(8, 2);

            out += ' ';
            out += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(11, 5);
            //out += '-';
            //out += this.settings.naplo_valasztottszolgaltatas_idopontig_datum.substr(11, 5);

            console.log('POPOVER_OUT: ' + out);

            return out;
        } else {
            return false;
        }
    }

    autocomplete_addname(name: string, id: string, phone: string, email: string) {
        console.log('Valasztott: ' + name);
        this.name = name;
        this.id = id;
        this.phone = phone;
        this.email = email;
        this.megjelenhetautocomp = false;
        this.utolsovalasztottname = name;
    }

    losefocus() {
        console.log('losefocus: ');
        this.megjelenhetautocomp = false;
    }

    get_serviceparams() {
        let postData = {
            'service_id': this.settings.naplo_valasztottszolgaltatas_szolgid,
        };

        this.http.setDataSerializer('json');
        this.http.post(this.serviceurl + 'serviceparams', postData, this.settings.httpauthheader())
            .then(data => {
                let sorok = JSON.parse(data.data);

                this.settings.naplo_valasztottszolgaltatas_szolgneve = sorok['serviceProperty']['name'];
                this.settings.naplo_valasztottszolgaltatas_szolgszine = sorok['serviceProperty']['color'].toLowerCase();
                this.settings.naplo_valasztottszolgaltatas_szolghossz = (parseInt(sorok['serviceProperty']['timelengthservice']) / parseInt(this.settings.deafultTimeFrame)).toString();
                // this.sorok = JSON.parse(data.data);
                console.log(data.status);
                console.log(data.data); // data received by server
                //this.set_kontaktok_lista(JSON.parse(_sorok));
                //console.log(sorok[1]['id']); // data received by server
                console.log(data.headers);
            })
            .catch(error => {
                //this.set_kontaktok_lista(JSON.parse(_sorok));
                console.log(error.status);
                console.log(error.error); // error message as string
                console.log(error.headers);
            });

    }




    autocomplete_search() {
        console.log('autsearch ' + this.form_kontaktok_egyuser.value['name']);

        if (this.utolsovalasztottname != this.form_kontaktok_egyuser.value['name'] || this.utolsovalasztottname == '') {
            this.utolsovalasztottname = '';
            this.megjelenhetautocomp = true;

            let postData = {
                'szo': this.form_kontaktok_egyuser.value['name'],
            };
            this.http.setDataSerializer('json');
            this.http.post(this.settings.serviceurl + 'company/userautocomplete', postData, this.settings.httpauthheader())
                .then(data => {
                    this.sorok = JSON.parse(data.data);
                    console.log(data.data);
                    //this.sorok = JSON.parse(this._sorok);
                    //console.log(data.status);
                    //console.log(data.data); // data received by server
                    //this.set_kontaktok_lista(JSON.parse(_sorok));
                    //console.log(sorok[1]['id']); // data received by server
                    //console.log(data.headers);
                })
                .catch(error => {
                    this.megjelenhetautocomp = false;
                    //this.set_kontaktok_lista(JSON.parse(_sorok));
                    console.log(error.status);
                    console.log(error.error); // error message as string
                    console.log(error.headers);
                });
        }

        return this.sorok;
    }

    get_form_kontaktok_egyuser() {
        let postData = {
            'id': this.id,
        };

        this.http.setDataSerializer('json');
        this.http.post(this.serviceurl + 'company/getuser', postData, this.settings.httpauthheader())
            .then(data => {
                //sorok = JSON.parse(data.data);
                this.sorok = JSON.parse(data.data);
                this.form_kontaktok_egyuser = this.fb.group({
                    email: this.sorok.email,
                    phone: this.sorok.phone,
                    name: this.sorok.name,
                });
                console.log(data.status);
                console.log(data.data); // data received by server
                //this.set_kontaktok_lista(JSON.parse(_sorok));
                //console.log(sorok[1]['id']); // data received by server
                console.log(data.headers);
            })
            .catch(error => {

                if (this.settings.naptarmodositas_email != '') {
                    this.form_kontaktok_egyuser = this.fb.group({
                        email: this.settings.naptarmodositas_email,
                        phone: this.settings.naptarmodositas_phone,
                        name: this.settings.naptarmodositas_name,
                    });
                    /*
                    this.email = this.settings.naptarmodositas_email;
                    this.name = this.settings.naptarmodositas_name;
                    this.phone = this.settings.naptarmodositas_phone;
                    this.settings.naptarmodositas_email = '';
                    this.settings.naptarmodositas_name = '';
                    this.settings.naptarmodositas_phone = '';
                    */
                }



                //this.set_kontaktok_lista(JSON.parse(_sorok));
                console.log(error.status);
                console.log(error.error); // error message as string
                console.log(error.headers);
            });

    }


    szolgclass_item() {
        return 'service-info ion_' + this.settings.naplo_valasztottszolgaltatas_szolgszine;
    }
    szolgclass_text() {
        return 'circle_dark_' + this.settings.naplo_valasztottszolgaltatas_szolgszine;
    }

    idopont() {
        let ido = this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(0, 4) + '.';
        ido += (this.settings.honapnevek[(parseInt(this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2)) - 1).toString()]) + '.';
        ido += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(8, 2) + ', ';
        ido += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(11, 5) + '-';



        let uxt = parseInt((new Date(this.settings.naplo_valasztottszolgaltatas_idopont_datum.toString()).getTime() / 1000).toFixed(0));
        if (typeof this.settings.naplo_valasztottszolgaltatas_szolghossz !== 'undefined') {
            let vege = uxt + (parseInt(this.settings.deafultTimeFrame) * parseInt(this.settings.naplo_valasztottszolgaltatas_szolghossz.toString()) * 60);
            let idovege = new Date(vege * 1000);

            console.log('uxt' + idovege.getHours());

            ido += (idovege.getHours().toString()) + ":" + ('0' + idovege.getMinutes().toString()).substr(-2, 2);

        }

        return ido;
        //ido +=
    }

    post_form_kontaktok_egyuser() {
        var valasz: string | { [x: string]: string; }[];
        //var navCtrl: NavController;
        //var settings: SettingsService;
        let postData: any;
        if (this.naplo_valasztottszolgaltatas_idopont_datum != '') {
            postData = {
                'email': this.form_kontaktok_egyuser.value['email'],
                'phone': this.form_kontaktok_egyuser.value['phone'],
                'name': this.form_kontaktok_egyuser.value['name'],
                'id': this.id,
                'kontakthozad': this.form_kontaktok_egyuser.value['kontakthozad'],
                'dateTime': (this.settings.naplo_valasztottszolgaltatas_idopont_datum).substr(0, (this.settings.naplo_valasztottszolgaltatas_idopont_datum).length - 3),
                'serviceId': this.settings.naplo_valasztottszolgaltatas_szolgid,
            };
        } else {
            postData = {
                'email': this.form_kontaktok_egyuser.value['email'],
                'phone': this.form_kontaktok_egyuser.value['phone'],
                'name': this.form_kontaktok_egyuser.value['name'],
                'kontakthozad': this.form_kontaktok_egyuser.value['kontakthozad'],
                'id': this.id,
            };
        }

        //console.log('post_form_kontaktok_egyuser '+JSON.stringify(this.settings.user));
        console.log('post_form_kontaktok_egyuser ' + JSON.stringify(postData));
        this.http.setDataSerializer('json');
        this.http.post(this.serviceurl + 'book/' + this.settings.user['sub'], postData, this.settings.httpauthheader())
            .then(data => {
                //console.log(data.data);
                this.popoverCtrl.dismiss();

                //valasz = JSON.parse(data.data);
                if (this.naplo_valasztottszolgaltatas_idopont_datum != '') {
                    this.settings.naplo_valasztottszolgaltatas_idopont_datum = '';
                    this.settings.naplo_valasztottszolgaltatas_szolgid = '';
                    this.settings.naplo_valasztottszolgaltatas_szolgneve = '';
                    this.sikeresfoglalas = 1;
                }
                //this.settings.token = valasz["token"];
                this.navCtrl.navigateRoot('tab_szolgaltato_naptar');
            }).catch(error => {

                console.log(error.error);


                valasz = JSON.parse(error.error);
                console.log('valasz' + valasz);
                for (var i = 0; i < valasz.length; i++) {
                    if (valasz[i]['field'] == 'name') {
                        this.hiba_name = valasz[i]['error'];
                    }
                    if (valasz[i]['field'] == 'email') {
                        this.hiba_email = valasz[i]['error'];
                    }
                    if (valasz[i]['field'] == 'phone') {
                        this.hiba_phone = valasz[i]['error'];
                    }

                }

                console.log(error.status);
                console.log(error.error); // error message as string
                console.log(error.headers);
                if (this.naplo_valasztottszolgaltatas_idopont_datum != '') {
                    this.settings.naplo_valasztottszolgaltatas_idopont_datum = '';
                    this.settings.naplo_valasztottszolgaltatas_idopontig_datum = '';
                    this.settings.naplo_valasztottszolgaltatas_szolgid = '';
                    this.settings.naplo_valasztottszolgaltatas_szolgneve = '';
                    this.sikeresfoglalas = 1;
                }

                //this.popoverCtrl.dismiss();
            });
    }


    ngOnInit() {
    }
}
