import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IonInfiniteScroll, PopoverController, NavController, Platform } from '@ionic/angular';
import { PopoverUseradatOffComponent } from '../popover-useradat-off/popover-useradat-off.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-naptar',
  templateUrl: './external-naptar.page.html',
  styleUrls: ['./external-naptar.page.scss'],
})
export class ExternalNaptarPage implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  lista: string = '';
  oldaltipus: string = '';
  oldaltipus_orig: string = '';
  userid: any = '';
  szolgrekid: string = null;

  sorokszama: string = "65";

  _class_tim: string = "";

  sorok: any = [];
  idopont_hasonlito: string = '';
  napkezdobetuje_hasonlito: string = '';

  szolgid: string = '';

  honapscroll: number;

  napaktualis: any;
  from: string;
  to: string;
  platform_height: number;

  page: number = 0;

  felirat_popover: string;
  felirat_megsem: string;
  felirat_torol: string;

  constructor(private http: HttpClient, public settings: SettingsService, public popoverCtrl: PopoverController, public navCtrl: NavController, private route: ActivatedRoute, private cdRef: ChangeDetectorRef, private platform: Platform) {
    this.idopont_hasonlito = settings.idopont_hasonlito;
    this.felirat_popover = this.settings.langobject.TORLODAFOGLALAST;
    this.felirat_megsem = this.settings.langobject.MEGSEM;
    this.felirat_torol = this.settings.langobject.TORLOM;

    platform.ready().then(() => {
      this.platform_height = platform.height();
      console.log("platform.height " + this.platform_height);
    });

    console.log("userid " + this.userid);

    /*
        if (this.route.snapshot.paramMap.get('userid') != null) {
          this.userid = this.route.snapshot.paramMap.get('userid');
          this.szolgid = this.route.snapshot.paramMap.get('szolgid');
          if (this.route.snapshot.paramMap.get('szolgrekid') != null) {
            this.szolgrekid = this.route.snapshot.paramMap.get('szolgrekid');
          }
          this.oldaltipus = "szabad";
          this.get_naptar_szolgaltato("szabad");
          console.log("userid " + this.userid);
        } else if (this.route.snapshot.paramMap.get('szolgid') != '') {
          this.szolgid = this.route.snapshot.paramMap.get('szolgid');
          if (this.route.snapshot.paramMap.get('oldaltipus') === '') {
            this.oldaltipus = 'szabad';
          } else {
            this.oldaltipus = this.route.snapshot.paramMap.get('oldaltipus');
          }
    
          this.from = this.settings.most.getUTCFullYear() + "-" + ("0" + (this.settings.most.getMonth() + 1).toString()).substr(-2, 2) + "-" + ("0" + this.settings.most.getUTCDate()).substr(-2, 2);
    
          this.napaktualis = this.settings.most.getTime();
    
          console.log("Oldaltipus: " + this.oldaltipus);
          console.log("Szolgid: " + this.szolgid);
          console.log("Idoponttol: " + this.from);
          this.get_naptar_szolgaltato(this.oldaltipus, '', '', this.from, '', this.page.toString(), this.sorokszama);
          //console.log("kovetkezohonapt "+this.kovetkezohonap("2019", "12"));
        } else if (this.route.snapshot.paramMap.get('oldaltipus') != '') {
          this.oldaltipus = this.route.snapshot.paramMap.get('oldaltipus');
    
        } else {
          this.oldaltipus = "szabad";
          this.get_naptar_szolgaltato("szabad");
          console.log("A tema itt2");
        }
        */

    this.get_naptar_szolgaltato();

    this.honapscroll = this.settings.most.getMonth();

    //console.log("naptar" + this.settings.most.);
  }

  random() {
    let rand = Math.floor(Math.random() * 20) + 1;
    return rand;
  }

  loadData(event) {
    setTimeout(() => {
      this.from = this.settings.most.getUTCFullYear() + "-" + ("0" + (this.settings.most.getMonth() + 1).toString()).substr(-2, 2) + "-" + ("0" + this.settings.most.getUTCDate()).substr(-2, 2);

      console.log("A tema itt to: " + this.from);

      this.page += Number(this.sorokszama);

      this.get_naptar_szolgaltato('', '', this.from, '', this.page.toString(), this.sorokszama);
      event.target.complete();

    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }

  honapneve() {
    //console.log("scroll:" + this.honapscroll);
    return this.settings.honapnevek[this.honapscroll];
  }

  honapneve_rov() {
    //console.log("scroll:" + this.settings.honapnevek_rovid[this.honapscroll]);
    return this.settings.honapnevek_rovid[this.honapscroll];
  }

  honap_elore() {
    //console.log("elore");
    var ho: any;
    if (this.honapscroll >= this.settings.honapnevek_rovid.length - 1) {
      this.honapscroll = 0;
    } else {
      this.honapscroll += 1;
    }
    this.sorok = [];
    if (this.honapscroll < this.settings.most.getMonth()) {
      ho = (this.settings.most.getFullYear() + 1) + "-" + ("0" + (this.honapscroll + 1).toString()).substr(-2, 2) + "-01";
    } else {
      ho = this.settings.most.getUTCFullYear().toString() + "-" + ("0" + (this.honapscroll + 1).toString()).substr(-2, 2) + "-01";
    }
    this.get_naptar_szolgaltato('', '', ho, '', this.page.toString(), this.sorokszama);
  }

  napnovelo(ev: string, ho: string, nap: string, mennyivel: number = 0) {
    var dat = ev + "-" + ("0" + ho).substr(-2, 2) + "-" + ("0" + (parseInt(nap) + mennyivel).toString()).substr(-2, 2);
    let date: Date = new Date(dat);
    console.log("1date " + date.getUTCDate());
    if (date.getTime()) {
      return dat;
    } else return '';
  }

  kovetkezohonap(ev: string, ho: string) {
    if (parseInt(ho) >= 12) {
      return (parseInt(ev) + 1).toString() + "-01-01";
    } else {
      return ev + "-" + ("0" + (parseInt(ho) + 1).toString()).substr(-2, 2) + "-01";
    }
  }

  honap_vissza() {
    //console.log("vissza");
    var ho: any;
    if (this.honapscroll <= 0) {
      this.honapscroll = this.settings.honapnevek_rovid.length - 1;
    } else {
      this.honapscroll -= 1;
    }
    this.sorok = [];
    if (this.honapscroll < this.settings.most.getMonth()) {
      ho = (this.settings.most.getFullYear() + 1) + "-" + ("0" + (this.honapscroll + 1).toString()).substr(-2, 2) + "-01";
    } else {
      ho = this.settings.most.getUTCFullYear().toString() + "-" + ("0" + (this.honapscroll + 1).toString()).substr(-2, 2) + "-01";
    }
    this.get_naptar_szolgaltato('', '', ho, ho, this.page.toString(), this.sorokszama);
  }
  /*
    getoldaltipusszabad() {
      this.oldaltipus = 'szabad';
      return 'szabad';
    }
  */

  ujfelv_szabadidopont() {
    document.getElementById('ujfelv_szabadidopont').setAttribute('class', 'timeline-switch-item timeline-switch-item-left active hydrated');
    document.getElementById('ujfelv_osszesidopont').setAttribute('class', 'timeline-switch-item timeline-switch-item-right hydrated');
    this.oldaltipus = 'szabad';
    this.sorok = [];
    this.page = 0;
    this.settings.idopont_hasonlito = "";
    this.from = this.settings.most.getUTCFullYear() + "-" + ("0" + (this.settings.most.getMonth() + 1).toString()).substr(-2, 2) + "-" + ("0" + this.settings.most.getUTCDate()).substr(-2, 2);
    this.get_naptar_szolgaltato("", "", this.from, '', this.page.toString(), this.sorokszama);
  }

  datum_nap(idopont: string) {
    return idopont.substr(5, 2);
  }
  datum_ora(idopont: string) {
    return idopont.substr(11, 5);
  }

  _idopont_hasonlito(has: string) {
    //console.log(this.idopont_hasonlito + "- _idopont_hasonlito!!! - " + has);
    this.idopont_hasonlito = has;
    this.settings.idopont_hasonlito = this.idopont_hasonlito;
  }

  tarcsaz(szam: string) {
    this.settings.tarcsaz(szam);
  }

  async popover_useradatmodositas(id: string, idopont: string, idopontig: string) {
    this.settings.naplo_valasztottszolgaltatas_idopont_datum = idopont;
    //this.settings.naplo_valasztottszolgaltatas_idopontig_datum = idopontig;
    console.log('this.settings.naplo_valasztottszolgaltatas_idopont_datum: ' + this.settings.naplo_valasztottszolgaltatas_idopont_datum);
    //console.log('this.settings.naplo_valasztottszolgaltatas_idopontig_datum: '+this.settings.naplo_valasztottszolgaltatas_idopontig_datum);
    const popover = await this.popoverCtrl.create({
      component: PopoverUseradatOffComponent,
      componentProps: { popoverCtrl: this.popoverCtrl },
    });
    return await popover.present();
  }


  ujelojegyzes() {
    this.navCtrl.navigateRoot('tab_szolgaltato_naptar_szolgaltatas');
  }

  class_tim(kellsorelotet: any) {
    return (kellsorelotet !== '' ? 'timeline-first' : '');
  }

  kontaktokbolfoglal_(idopont: string, idopontig: string) {
    let postData = {
      "hash": this.settings.off_hash,
      "serviceId": this.szolgid,
      "dateTime": (idopont).substr(0, (idopont).length - 3),
    }
    console.log("konaktokbol");
    console.log(postData);

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'book/foglalkontaktokboloff');
    this.http.post(this.settings.serviceurl + 'book/foglalkontaktokboloff', postData, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });    
/*
    this.http.post(this.serviceurl + 'book/foglalkontaktokbol', postData, this.settings.httpauthheader())
      .then(data => {
        this.sorok = JSON.parse(data.data);
        console.log(data.status);
        console.log(data.data); // data received by server
        console.log(data.headers);
        this.navCtrl.navigateRoot('tab_szolgaltato_kontaktok_egyuser/' + this.userid);
      })
      .catch(error => {
        console.log(error.status);
        console.log(error.error); // error message as string
        console.log(error.headers);
        this.navCtrl.navigateRoot('tab_szolgaltato_kontaktok_egyuser/' + this.userid);
      });
*/
  }

  get_naptar_szolgaltato(id: string = '', honap: string = '', from: string = '', to: string = '', page: string = '', limit: string = '') {
    let postData: any;

    if (honap == '') {
      honap = (new Date().getUTCFullYear()).toString() + ("0" + (new Date().getMonth() + 1)).substr(-2, 2);
      console.log("nemvolt " + honap);
    }

    var honap_: number = parseInt(honap.substr(-2, 2));
    var ev_: number = parseInt(honap.substr(0, 4));

    let mosthonap_ = (new Date().getMonth()) + 1;
    var from_dat: any;


    console.log("mostfrom_ " + from);
    console.log("mostto_ " + to);

    if (from !== '') {
      from_dat = from;
    } else if (mosthonap_ == honap_) {
      from_dat = honap.substr(0, 4) + "-" + honap.substr(-2, 2) + "-" + ("0" + (new Date().getUTCDate())).substr(-2, 2);
    } else {
      from_dat = honap.substr(0, 4) + "-" + honap.substr(-2, 2) + "-" + "01";
    }
    console.log("mosthonap_ " + mosthonap_);
    console.log("honap " + honap);
    console.log("honap_ " + honap_);
    console.log("ev_" + ev_);
    //let day = new Date(ev_, honap_, 0).getDate();
    var to_dat: any;
    if (to !== '') {
      to_dat = to;
    } else {
      to_dat = '';
      //to_dat = (parseInt(honap.substr(0, 4)) + 10) + "-" + honap.substr(-2, 2) + "-" + day;
    }

    console.log("to_dat_ " + to_dat);
    console.log("from_dat_ " + from_dat);

    postData = {
      "from": from_dat,
      "to": to_dat,
      "page": page,
      "limit": limit,
      "hash": this.settings.off_hash,
    }

    //console.log(this.serviceurl + 'calendar');




    let queryString = Object.keys(postData).map(key => key + '=' + postData[key]).join('&');
    console.log("postData " + JSON.stringify(postData));

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'calendaroff/?' + queryString);
    this.http.get(this.settings.serviceurl + 'calendaroff/?' + queryString, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);

        //var sorok_kivulrol = JSON.parse(data.data);
        var sorok_kivulrol = data;
        var sorok = [];
        var honapnevek = this.settings.honapnevek;
        var honapnevek_rovid = this.settings.honapnevek_rovid;
        var nap_mon = this.settings.langobject.Mon;
        var nap_tue = this.settings.langobject.Tue;
        var nap_wed = this.settings.langobject.Wed;
        var nap_thu = this.settings.langobject.Thu;
        var nap_fri = this.settings.langobject.Fri;
        var nap_sat = this.settings.langobject.Sat;
        var nap_sun = this.settings.langobject.Sun;
        var napmost: string = '';
        var kellsorelotet: string = '';
        var idopont_hasonlito = this.settings.idopont_hasonlito;

        //var teszt_rand: any;
        var teszt_nev: any;
        var teszt_szin: any;
        var teszt_process_id: any;

        var most_szin: any;
        var most_nev: any;
        var most_process_id: any;

        var idoszamlalo: number = 0;
        var most_idopont: any;

        var megjelenhet: boolean = false;

        var lastpush: number = 0;

        //var stop = 0;

        console.log("data.data " + data);

        Object.keys(sorok_kivulrol["days"]).forEach(function (key, index) {
          console.log("foglalhato? " + sorok_kivulrol["days"][key]["foglalhato"]);
          if (sorok_kivulrol["days"][key]["free"] == true && sorok_kivulrol["days"][key]["foglalhato"] == true) {
            // Foglalhato es szabad
            switch (sorok_kivulrol["days"][key]["dayName"]) {
              case "Mon":
                napmost = nap_mon;
                break;
              case "Tue":
                napmost = nap_tue;
                break;
              case "Wed":
                napmost = nap_wed;
                break;
              case "Thu":
                napmost = nap_thu;
                break;
              case "Fri":
                napmost = nap_fri;
                break;
              case "Sat":
                napmost = nap_sat;
                break;
              case "Sun":
                napmost = nap_sun;
                break;
            }
            teszt_process_id = sorok_kivulrol["days"][key]["process_id"];
            teszt_nev = "";
            teszt_szin = "";
            //}
            most_idopont = "";
            if (teszt_process_id === 0 && teszt_szin === "") {
              idoszamlalo = 1;
              //most_idopont = (key + " " + keycell);
              most_idopont = (sorok_kivulrol["days"][key]["start"]);
              //console.log("URESS " + tipus + " " + most_idopont + " - " + teszt_nev + " - " + idoszamlalo);

            } else if (most_szin === teszt_szin && most_process_id === teszt_process_id) {
              idoszamlalo = 1;
              //idoszamlalo += 1;
              //console.log("DUPLAMIN"+" - "+idoszamlalo);
            } else {
              most_nev = teszt_nev;
              most_szin = teszt_szin;
              most_process_id = teszt_process_id;
              //idoszamlalo = 1;
              //most_idopont = (key + " " + keycell);
              most_idopont = (sorok_kivulrol["days"][key]["start"]);
              //console.log("NEMU " + most_idopont + " - " + teszt_nev + " - " + idoszamlalo);
            }

            megjelenhet = false;
            if (idoszamlalo === 0) {
              idoszamlalo = 1;
            }
            if (teszt_nev === '' && teszt_szin === '') {
              megjelenhet = true;
            }

            if (megjelenhet === true) {
              if (idopont_hasonlito !== (sorok_kivulrol["days"][key]["start"]).substr(8, 2)) {
                idopont_hasonlito = (sorok_kivulrol["days"][key]["start"]).substr(8, 2);
                kellsorelotet = idopont_hasonlito;
              } else {
                kellsorelotet = '';
              }

              sorok.push({
                "id": ("0"),
                "service_id": ("0"),
                "phone": (""),
                "idopont": (sorok_kivulrol["days"][key]["start"]),
                "honapneve": (honapnevek[(parseInt(key.substr(5, 2)) - 1).toString()]),
                "honapneve_rov": (honapnevek_rovid[(parseInt(key.substr(5, 2)) - 1).toString()]),
                "kellsorelotet": kellsorelotet,
                "napkezdobetuje": (napmost),
                "idotav": ("1"),
                "name": (teszt_nev),
                "foglalhato": (sorok_kivulrol["days"][key]["foglalhato"]),
                "napaktualis": (key.substr(0, 4) + key.substr(5, 2) + key.substr(8, 2)),
                "valasztottszin": (teszt_szin),
              });
              lastpush += 1;

            }
            idoszamlalo = 1;
          } else {
            /**********************************************************************/
            console.log("FFF 1");
            /* foglalt az idopont */
            switch (sorok_kivulrol["days"][key]["dayName"]) {
              case "Mon":
                napmost = nap_mon;
                break;
              case "Tue":
                napmost = nap_tue;
                break;
              case "Wed":
                napmost = nap_wed;
                break;
              case "Thu":
                napmost = nap_thu;
                break;
              case "Fri":
                napmost = nap_fri;
                break;
              case "Sat":
                napmost = nap_sat;
                break;
              case "Sun":
                napmost = nap_sun;
                break;
            }

            teszt_process_id = sorok_kivulrol["days"][key]["process_id"];
            teszt_nev = sorok_kivulrol["days"][key]["name"];

            try {
              teszt_szin = sorok_kivulrol["days"][key]["service"]["rgb"];
            } catch (error) {
              teszt_szin = "-1";
            }



            most_idopont = "";
            console.log("-----------------------------------");
            console.log("start " + sorok_kivulrol["days"][key]["start"]);
            console.log("most_szin " + most_szin + " /  teszt_szin " + teszt_szin);
            console.log("most_nev " + most_nev + " /  teszt_nev " + teszt_nev);

            console.log("FFF 55");
            if (teszt_process_id === 0 && teszt_szin === "") {
              idoszamlalo = 1;
              console.log("FFF 555");
              most_idopont = (sorok_kivulrol["days"][key]["start"]);
              console.log("FFF 5555");

            } else if (most_szin === teszt_szin && most_process_id === teszt_process_id) {
              idoszamlalo += 1;
              sorok[lastpush - 1]["idotav"] = idoszamlalo;
            } else {
              most_process_id = teszt_process_id;
              most_nev = teszt_nev;
              most_szin = teszt_szin;
              most_idopont = (sorok_kivulrol["days"][key]["start"]);
            }


            console.log("idoszamlalo" + " - " + idoszamlalo);
            console.log("most_idopont" + " - " + most_idopont);
            megjelenhet = false;
            if (most_idopont !== "") {
              if (idoszamlalo === 0) {
                idoszamlalo = 1;
              }
              if (teszt_nev === '' && teszt_szin === '') {
                megjelenhet = true;
              }
              console.log("idoszamlalo_b" + " - " + idoszamlalo);

              if (megjelenhet === true) {

                if (idopont_hasonlito !== (sorok_kivulrol["days"][key]["start"]).substr(8, 2)) {
                  idopont_hasonlito = (sorok_kivulrol["days"][key]["start"]).substr(8, 2);
                  kellsorelotet = idopont_hasonlito;
                } else {
                  kellsorelotet = '';
                }
                console.log("FFF 6-");

                if (sorok_kivulrol["days"][key]["foglalhato"] == false && sorok_kivulrol["days"][key]["free"] == true) {
                  console.log("FFF 6");

                  sorok.push({
                    "id": ("0"),
                    "service_id": ("0"),
                    "phone": (""),
                    "idopont": most_idopont,
                    "honapneve": (honapnevek[(parseInt(key.substr(5, 2)) - 1).toString()]),
                    "honapneve_rov": (honapnevek_rovid[(parseInt(key.substr(5, 2)) - 1).toString()]),
                    "kellsorelotet": kellsorelotet,
                    "napkezdobetuje": (napmost),
                    "idotav": (idoszamlalo),
                    "name": (""),
                    "foglalhato": false,
                    "napaktualis": (key.substr(0, 4) + key.substr(5, 2) + key.substr(8, 2)),
                    "valasztottszin": (teszt_szin.toLowerCase()),
                  });
                  console.log("FFF 7");

                } else {
                  sorok.push({
                    "id": sorok_kivulrol["days"][key]["process_id"],
                    "service_id": sorok_kivulrol["days"][key]["service_id"],
                    "phone": sorok_kivulrol["days"][key]["phone"],
                    "idopont": most_idopont,
                    "honapneve": (honapnevek[(parseInt(key.substr(5, 2)) - 1).toString()]),
                    "honapneve_rov": (honapnevek_rovid[(parseInt(key.substr(5, 2)) - 1).toString()]),
                    "kellsorelotet": kellsorelotet,
                    "napkezdobetuje": (napmost),
                    "idotav": (idoszamlalo),
                    "name": (teszt_nev + ":" + sorok_kivulrol["days"][key]["service"]["name"]),
                    "foglalhato": true,
                    "napaktualis": (key.substr(0, 4) + key.substr(5, 2) + key.substr(8, 2)),
                    "valasztottszin": (teszt_szin.toLowerCase()),
                  });
                }

                lastpush += 1;
              }
              idoszamlalo = 1;
            }
          }
        });
        sorok.forEach(element => {
          this.sorok.push(element);
        });

        this.settings.idopont_hasonlito = idopont_hasonlito;



        console.log(sorok);

      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });
  }

  ngOnInit() {
  }

}
