import { ExternalModdelPage } from './external-moddel/external-moddel.page';
import { HomePage } from './home/home.page';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { SettingsService } from './settings.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { EmbeddedPage } from './embedded/embedded.page';
import { ExternalNaptarPageModule } from './external-naptar/external-naptar.module';
import { ExternalNaptarSzolgaltatasPageModule } from './external-naptar-szolgaltatas/external-naptar-szolgaltatas.module';
import { EmbeddedNaptarPageModule } from './embedded-naptar/embedded-naptar.module';
import { EmbeddedNaptarSzolgaltatasPageModule } from './embedded-naptar-szolgaltatas/embedded-naptar-szolgaltatas.module';
import { ExternalMerchantPasswordrequestPageModule } from './external-merchant-passwordrequest/external-merchant-passwordrequest.module';
import { ExternalMerchantNewpasswordPageModule } from './external-merchant-newpassword/external-merchant-newpassword.module';
import { EmbeddedPasswordrequestPageModule } from './embedded-passwordrequest/embedded-passwordrequest.module';
import { EmbeddedNewpasswordPageModule } from './embedded-newpassword/embedded-newpassword.module';
import { HttpClient } from '@angular/common/http';
//import { PopoverIdoszelektorComponent } from './popover_idoszelektor/popover_idoszelektor.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    public settings: SettingsService,
    public navCtrl: NavController,
    public deeplinks: Deeplinks
  ) {
    this.initializeApp();
    this.backButtonEvent();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      //console.log("BackB");
      //navigator['app'].exitApp();
      this.navCtrl.back();
    });
  }

  open_tab_szolgaltato_naptar() {
    this.menu.close();
    this.navCtrl.navigateRoot('tab_szolgaltato_naptar');
  }

  open_tab_szolgaltato_beallitas() {
    this.menu.close();
    this.navCtrl.navigateRoot('tab_szolgaltato_beallitas');
  }

  open_tab_szolgaltato_kontaktok() {
    console.log("kontaktok");
    this.menu.close();
    this.navCtrl.navigateRoot('tab_szolgaltato_kontaktok');
  }

  open_tab_szolgaltato_ertesitesek() {
    this.menu.close();
    this.navCtrl.navigateRoot('tab_szolgaltato_ertesitesek');
  }

  open_tab_szolgaltato_sugo() {
    this.menu.close();
    this.navCtrl.navigateRoot('tab_szolgaltato_sugo');
  }

  logout() {
    this.settings.token = '';
    this.menu.close();
    this.navCtrl.navigateRoot('home');
  }


  post_countertesites () {
    console.log('post_countertesites');


    this.http.get(this.settings.serviceurl + 'company/countertesitesek', this.settings.httpauth_header_angular())
    .subscribe(data => {
      //console.log("valasz");
      //console.log(data);
      this.settings.countertesitesek=data["szam"];
     
    }, err => {
      console.log('HError: ' + err.headers);
      console.log('Error: ' + err.error);
    });    

  } 


  ngAfterViewInit() {

    console.log ("ATMENTAPPC "+this.settings.token);


    this.platform.ready().then(() => {
      this.deeplinks.route({
        '/embedded': EmbeddedPage,
        '/external-moddel': ExternalModdelPage,
        '/external-moddel/:hash': ExternalModdelPage,
        '/home': HomePage,
        '/external-naptar': ExternalNaptarPageModule,
        '/external-naptar-szolgaltatas': ExternalNaptarSzolgaltatasPageModule,
        '/embedded-naptar': EmbeddedNaptarPageModule,
        '/embedded-naptar-szolgaltatas': EmbeddedNaptarSzolgaltatasPageModule,
        '/external-merchant-passwordrequest': ExternalMerchantPasswordrequestPageModule,
        '/external-merchant-newpassword': ExternalMerchantNewpasswordPageModule,
        '/external-merchant-newpassword/:hash': ExternalMerchantNewpasswordPageModule,
        '/embedded-passwordrequest': EmbeddedPasswordrequestPageModule,
        '/embedded-newpassword': EmbeddedNewpasswordPageModule,
        '/embedded-newpassword/:hash': EmbeddedNewpasswordPageModule,   
      }).subscribe(match => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        console.log('Successfully matched route', match);
      }, nomatch => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });      
    });
  }
}
