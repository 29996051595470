import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EmbeddedNaptarSzolgaltatasPageRoutingModule } from './embedded-naptar-szolgaltatas-routing.module';

import { EmbeddedNaptarSzolgaltatasPage } from './embedded-naptar-szolgaltatas.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EmbeddedNaptarSzolgaltatasPageRoutingModule
  ],
  declarations: [EmbeddedNaptarSzolgaltatasPage]
})
export class EmbeddedNaptarSzolgaltatasPageModule {}
