import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NavParams, PopoverController } from '@ionic/angular';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-popover_idoszelektor',
  templateUrl: './popover_idoszelektor.component.html',
  styleUrls: ['./popover_idoszelektor.component.scss'],
})

export class PopoverIdoszelektorComponent implements OnInit {

  @ViewChild('ido_nyitas') _ido_nyitas;
  @ViewChild('ido_zaras') _ido_zaras;

  idoszelektorform: FormGroup;
  popoverCtrl: PopoverController;
  //navParams: NavParams;
  //exp_nyitas: string;
  //exp_zaras: string;
  //idoszelektor: String;
  constructor(private fb: FormBuilder, public navParams: NavParams, public settings: SettingsService) {
    //var idoszelektor = document.getElementById('idoszelektor');
    //this.navParams = params;

    //var nyit: any = this.navParams.get('szolgaltatoCtrl').
    //console.log("mom " + moment('2019-04-04T16:00:30+02:00').format());
    var _tol: any;
    var _ig: any;
    if (this.navParams.get('tol')=='') {
      _tol='00:00';
    } else {
      _tol=this.navParams.get('tol');
    }
    if (this.navParams.get('ig')=='') {
      _ig='00:00';
    } else {
      _ig=this.navParams.get('ig');
    }

    this.idoszelektorform = this.fb.group({
      ido_nyitas: moment('2019-04-04T'+_tol+':30+02:00').format(),
      ido_zaras: moment('2019-04-04T'+_ig+':30+02:00').format(),
    });
   
    this.popoverCtrl = navParams.get('popoverCtrl');
    console.log("tol " + _tol);
    console.log("ig " + _ig);

  }
  click_zarnyit(tipus: string) {
    var nyitas = document.getElementById('nyitas');
    var zaras = document.getElementById('zaras');
    if (tipus == 'nyitas') {
      this._ido_nyitas.open();
      /*
      this.idoszelektorform = this.fb.group({
        ido_nyitas: '2019-04-04T08:00:30',
      });
      */
      nyitas.style.background = '#007300';
      zaras.style.background = '#ffffff';
    } else {
      this._ido_zaras.open();
      /*
      this.idoszelektorform = this.fb.group({
        ido_zaras: moment('2019-04-04T16:00:30+02:00').format(),
      });
      */
      nyitas.style.background = '#ffffff';
      zaras.style.background = '#007300';
    }
  }
  post_form_idoszelektor() {
    //console.log("idrenyomott " + this._ido_nyitas.value);
    if (this._ido_nyitas.value.length<=5) {
      this._ido_nyitas.value = moment('2019-04-04T'+this._ido_nyitas.value+':30+02:00').format();
    }
    if (this._ido_zaras.value.length<=5) {
      this._ido_zaras.value = moment('2019-04-04T'+this._ido_zaras.value+':30+02:00').format();
    }

    //this._ido_nyitas.value;
    this.navParams.get('szolgaltatoCtrl').post_form_idoszelektor(moment(this._ido_nyitas.value).format('HH:mm'), moment(this._ido_zaras.value).format('HH:mm'));
    //this.navParams.get('szolgaltatoCtrl').post_form_idoszelektor(this._ido_nyitas.value, this._ido_zaras.value);
    this.popoverCtrl.dismiss();
  }
  ngOnInit() {
    this._ido_nyitas.open();

  }
}
