import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-external-moddel',
  templateUrl: './external-moddel.page.html',
  styleUrls: ['./external-moddel.page.scss'],
})
export class ExternalModdelPage implements OnInit {
  companyname: String;
  szolgneve: String;
  idopont: String;
  hash: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, public settings: SettingsService, public navCtrl: NavController) {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.settings.off_hash = this.hash;
    //if (this.route.snapshot.paramMap.get('hash') != null) {
    console.log("userid " + this.route.snapshot.paramMap.get('hash'));
    //}
  }

  get_adatok() {

    let postData = {
      "hash": this.hash,
      "action": "adatok"
    }

    //let postData = "hash="+this.hash+"&action=adatok";

    let queryString = Object.keys(postData).map(key => key + '=' + postData[key]).join('&');

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'company/external-moddel/?' + queryString);
    this.http.get(this.settings.serviceurl + 'company/external-moddel/?' + queryString, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.companyname = data["companyname"];
        this.szolgneve = data["elojegyzesek"][0]["szolgneve"];
        this.idopont = data["elojegyzesek"][0]["idopont"];
      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });


  }

  open_torles() {
    var fejlec = document.getElementById('fejlec');
    var torles = document.getElementById('torles');
    //var idotatallit = document.getElementById('idotatallit');
    fejlec.style.display = 'none';
    torles.style.display = 'block';
    //idotatallit.style.display = 'none';
  }

  open_modositas() {
    /*
        var fejlec = document.getElementById('fejlec');
        var torles = document.getElementById('torles');
        var idotatallit = document.getElementById('idotatallit');
    
        fejlec.style.display = 'none';
        torles.style.display = 'none';
        idotatallit.style.display = 'block';
    */
    console.log("Modositasindul");
    this.navCtrl.navigateRoot('external-naptar-szolgaltatas');

  }

  open_torles_torol() {

    let postData = {
      "hash": this.hash,
      "action": "torol"
    }

    let queryString = Object.keys(postData).map(key => key + '=' + postData[key]).join('&');

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'company/external-moddel/?' + queryString);
    this.http.get(this.settings.serviceurl + 'company/external-moddel/?' + queryString, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });

    var foglalastorolve = document.getElementById('foglalastorolve');
    var fejlec = document.getElementById('fejlec');
    var torles = document.getElementById('torles');
    //var idotatallit = document.getElementById('idotatallit');

    foglalastorolve.style.display = 'block';
    fejlec.style.display = 'none';
    torles.style.display = 'none';
    //idotatallit.style.display = 'none';



  }

  open_torles_megsem() {
    var fejlec = document.getElementById('fejlec');
    var torles = document.getElementById('torles');
    //var idotatallit = document.getElementById('idotatallit');

    fejlec.style.display = 'block';
    torles.style.display = 'none';
    //idotatallit.style.display = 'none';
  }


  ngOnInit() {
    this.get_adatok();
    /*
    this.fejlec.style.display = 'block';
    this.torles.style.display = 'none';
    this.idotatallit.style.display = 'none';    
    */
  }

}
