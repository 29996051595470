import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Platform, NavController } from '@ionic/angular';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public serviceurl = 'https://tbook24.wi24.hu/api/1.0/';
  public token = '';

  public tokenemb = '';

  public nincsbeallitvamunkaido = 0;
  public nincsbeallitvaszolgaltatas = 0;

  public countertesitesek = 0;

  public _kovetkezomunkanap: string = '';
  public _vanefoglalas = 0;

  public frissregszekvencia = 0;
  public user: any;
  public lang: string = 'hu';
  private lang_hu: string = '{"tab_szolgaltato_beallitas_elojegyzes_idoszakok":"A legkésőbbi foglalható időpont, az aktuális foglálastól számítva",'
    + '"tab_szolgaltato_beallitas_elojegyzes_foglalaslegkesobb":"A legkorábbi foglalható időpont, a szolgáltatás igénybevételétől számítva.",'
    + '"tab_szolgaltato_reg_felhasznalonev":"felhasznalonev info magyar",'
    + '"tab_szolgaltato_reg_aszf":"Általános szerződési feltételeket\\"alma\\".",'
    + '"tab_szolgaltato_reg_aszf2":"Általános szerződési feltételeket\\"szilva\\".",'
    + '"INVALID_LOGIN":"Érvénytelen login paraméterek.",'
    + '"ACTIVATE_REGISTRATION":"A regisztráció nincs aktiválva.",'
    + '"INVALID_EMAIL":"Érvénytelen e-mail cím.",'
    + '"BUSY_EMAIL":"Foglalt e-mail cím.",'
    + '"UNKNOWN_EMAIL":"Ismeretlen e-mail cím.",'
    + '"BUSY_DOMAIN":"Foglalt domain név.",'
    + '"BUSY_USER_NAME":"Foglalt felhasználói név.",'
    + '"MISSING_USER_NAME":"Felhasználónév nem lett megadva.",'
    + '"MISSING_EMAIL":"Az email cím nem lett megadva.",'
    + '"MISSING_PHONE":"A telefonszám nem lett megadva.",'
    + '"MISSING_DOMAIN":"A domain nem lett megadva.",'
    + '"MISSING_COMPANY_NAME":"A vezetéknév/keresztnév mező nem lett kitöltve.",'
    + '"MISSING_PASSWORD":"A jelszó nem lett megadva.",'
    + '"NPW_NOT_EXISTS":"Az új jelszó túl rövid vagy hiányzik.",'
    + '"TOOSHORT_PASSWORD":"A jelszó túl rövid.",'
    + '"EMPTY_PHONE":"A telefonszám nem lett megadva.",'
    + '"INVALID_PHONE":"A telefonszám érvénytelen.",'
    + '"EMPTY_NAME":"A név nem lett megadva.",'
    + '"NOT_NUMERIC_PRICE":"Az ár hibásan lett megadva.",'
    + '"INVALID_COLOR":"A szolgáltatás színe nem lett megadva.",'
    + '"NOT_CORRECT_TIME":"Az alapértelmezett időkeret: !perc! perc. A megadott időnek maradék nélkül oszthatónak kell lennie az alapértelmezett időkerettel. ",'
    + '"INVALID_FB_TOKEN":"Érvénytelen facebook login.",'
    + '"INVALID_GE_TOKEN":"Érvénytelen google login.",'
    + '"IT_HAS_BUSY":"Az időtartományra van bejegyzett foglalás.",'
    + '"VANAZONTUL":"Az időtartományon túl is van bejegyzett foglalás.",'
    + '"PW_ORIG_NOT_FOUND":"Az eredeti jelszó hibás.",'
    + '"MISSING_NAME":"A név nem lett megadva.",'
    + '"PW_NOT_EQUALS":"Az új jelszó és az ellenőrző jelszó nem egyezik meg.",'
    + '"DOMAINCHANGEERR":"Domain vátlas csak !x! naponta lehetséges.",'
    + '"DOMAINCHANGE":"A legközelebbi domain vátlas csak !x! nap elteltével lehetséges.",'
    + '"TORLODAFOGLALAST":"Törlöd a foglalást?",'
    + '"TORLODASZABADSAGOT":"Törlöd a szabadság bejegyzést?",'
    + '"TORLODAFELHASZNALOT":"Törlöd a felhasználót?",'
    + '"TULMERETESKEP":"Túl nagy képet próbálsz feltölteni. A maximális fileméret 8Mb.",'
    + '"HIBAAKEPFELTOLTESNEL":"Hiba a kép feltöltésekor.",'
    + '"MEGSEM":"Mégsem",'
    + '"TORLOM":"Törlöm",'
    + '"E_USER_ERROR":"Az időpont nem foglalható!",'
    + '"Mon":"H",'
    + '"Tue":"K",'
    + '"Wed":"Sze",'
    + '"Thu":"Cs",'
    + '"Fri":"P",'
    + '"Sat":"Szo",'
    + '"Sun":"V",'

    + '"menu_naptar":"Naptár",'
    + '"menu_beallitas":"Beállítás",'
    + '"menu_kontaktok":"Kontaktok",'
    + '"menu_ertesitesek":"Értesítések",'
    + '"menu_sugo":"Súgó",'
    + '"menu_kilepes":"Kilépés",'

    + '"tab_szolgaltato_beallitas_nyelv_fejlec":"Nyelv beállítása",'


    + '"menu_tab_szolgaltato_beallitas_fejlec":"Beállítások",'
    + '"menu_tab_szolgaltato_beallitas_adataim":"Adataim",'
    + '"menu_tab_szolgaltato_beallitas_szolgaltatasok":"Szolgáltatások",'
    + '"menu_tab_szolgaltato_beallitas_munkaido":"Munkaidő",'
    + '"menu_tab_szolgaltato_beallitas_szabadsag":"Szabadság",'
    + '"menu_tab_szolgaltato_beallitas_ertesito_uzenet":"Értesítő üzenet",'
    + '"menu_tab_szolgaltato_beallitas_fblekapcs":"Facebook leválasztás",'
    + '"menu_tab_szolgaltato_beallitas_nyelv":"Szolgáltatás nyelve",'
    + '"menu_tab_szolgaltato_beallitas_marketing":"Marketing",'


    + '"tab_szolgaltato_beallitas_l_fejlec":"Adataim",'
    + '"tab_szolgaltato_beallitas_l_felhasznalonev":"Felhasználó név",'
    + '"tab_szolgaltato_beallitas_l_vezeteknev":"Vezetéknév/Keresztnév",'
    + '"tab_szolgaltato_beallitas_l_domain":"Domain",'
    + '"tab_szolgaltato_beallitas_l_telefonszam":"Telefonszám",'
    + '"tab_szolgaltato_beallitas_l_email":"E-mail cím",'
    + '"tab_szolgaltato_beallitas_l_jelszom":"Jelszó módosítás",'
    + '"tab_szolgaltato_beallitas_l_jelszo":"Jelszó",'
    + '"tab_szolgaltato_beallitas_l_ujjelszo":"Új jelszó",'
    + '"tab_szolgaltato_beallitas_l_ujjelszom":"Új jelszó megerősítése",'
    + '"tab_szolgaltato_beallitas_l_modositom":"Módosítom",'


    + '"tab_szolgaltato_beallitas_munkaido_l_fejlec":"Munkaidő",'
    + '"tab_szolgaltato_beallitas_munkaido_l_elojegyezhetoidoszak":"Előjegyezhető időszak",'
    + '"tab_szolgaltato_beallitas_munkaido_l_foglalaslegkesobb":"Foglalás legkésőbb",'
    + '"tab_szolgaltato_beallitas_munkaido_l_modositom":"Módosítom",'
    + '"tab_szolgaltato_beallitas_munkaido_l_valtott":"Váltott műszak A/B hét",'
    + '"tab_szolgaltato_beallitas_munkaido_l_varjon":"Kérem várjon...",'


    + '"tab_szolgaltato_beallitas_szabadsag_l_fejlec":"Szabadság",'
    + '"tab_szolgaltato_beallitas_szabadsag_l_eleje":"Első napja",'
    + '"tab_szolgaltato_beallitas_szabadsag_l_vege":"Utolsó napja",'
    + '"tab_szolgaltato_beallitas_szabadsag_l_beallitom":"Beállítom",'
    + '"tab_szolgaltato_beallitas_szabadsag_l_mentes":"Mentés",'

    + '"tab_szolgaltato_beallitas_ertesitouzenet_l_fejlec":"Értesítő üzenet",'
    + '"tab_szolgaltato_beallitas_ertesitouzenet_l_ism":"Itt tudod beállítani az értesítő üzenetet.",'
    + '"tab_szolgaltato_beallitas_ertesitouzenet_l_uzenet":"Üzenet",'
    + '"tab_szolgaltato_beallitas_ertesitouzenet_l_beallitom":"Beállítom",'


    + '"tab_szolgaltato_beallitas_szolgaltatasok_l_fejlec":"Szolgáltatások",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_fejlec":"Szolgáltatás hozzáadása",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_szneve":"Szolgáltatás neve",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_idot":"Időtartama (perc)",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_tobbsz":"Többszakaszos szolgáltatás",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_elsosz":"Első szakasz",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_szunet":"Szünet",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_masodiksz":"Második szakasz",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_szszine":"Szolgáltatás színe",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_ara":"Ára",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_ism":"Szolgáltatás ismertetése",'
    + '"tab_szolgaltato_beallitas_szolgaltatasok_add_l_tovabb":"Tovább",'

    + '"tab_szolgaltato_beallitas_fblekapcs_l_fejlec":"Facebook leválasztás",'
    + '"tab_szolgaltato_beallitas_fblekapcs_l_button":"facebook leválasztás",'

    + '"tab_szolgaltato_ertesitesek_l_fejlec":"Értesítések",'

    + '"tab_szolgaltato_kontaktok_l_fejlec":"Kontaktok",'
    + '"tab_szolgaltato_kontaktok_l_kereses":"Keresés",'


    + '"tab_szolgaltato_kontaktok_egyuser_l_nev":"Név",'
    + '"tab_szolgaltato_kontaktok_egyuser_l_telefon":"Telefonszám",'
    + '"tab_szolgaltato_kontaktok_egyuser_l_email":"E-mail cím",'
    + '"tab_szolgaltato_kontaktok_egyuser_l_cimkek":"Cimkék",'
    + '"tab_szolgaltato_kontaktok_egyuser_l_modositom":"Módosítom",'

    + '"tab_szolgaltato_naptar_l_fejlec":"Naptár",'
    + '"tab_szolgaltato_naptar_l_szabadidopont":"Szabad időpontok",'
    + '"tab_szolgaltato_naptar_l_osszesidopont":"Összes időpont",'
    + '"tab_szolgaltato_naptar_l_foglaltido":"Foglalt időpontok",'

    + '"tab_szolgaltato_sugo_l_fejlec":"Segíthetünk?",'
    + '"tab_szolgaltato_sugo_l_tk":"Top kulcsszavak",'

    + '"tab_szolgaltato_reg_l_fejlec":"Regisztráció",'
    + '"tab_szolgaltato_reg_l_fbreg":"regisztráció facebokkal",'
    + '"tab_szolgaltato_reg_l_gereg":"regisztráció google-lal",'

    + '"tab_szolgaltato_reg_l_fnev":"Felhasználó név",'
    + '"tab_szolgaltato_reg_l_domain":"Domain",'
    + '"tab_szolgaltato_reg_l_telefon":"Telefonszám",'
    + '"tab_szolgaltato_reg_l_email":"E-mail cím",'
    + '"tab_szolgaltato_reg_l_vezker":"Vezetéknév/Keresztnév",'
    + '"tab_szolgaltato_reg_l_jelszo":"Jelszó",'
    + '"tab_szolgaltato_reg_l_cim":"Cím",'
    + '"tab_szolgaltato_reg_l_tovabb":"Tovább",'

    + '"tab_szolgaltato_login_l_fejlec":"Bejelentkezés",'
    + '"tab_szolgaltato_login_l_fblogin":"Facebook",'
    + '"tab_szolgaltato_login_l_googlelogin":"Google",'
    + '"tab_szolgaltato_login_l_email":"E-mail cím",'
    + '"tab_szolgaltato_login_l_jelszo":"Jelszó",'
    + '"tab_szolgaltato_login_l_tovabb":"Tovább",'
    + '"tab_szolgaltato_login_l_belepes":"Bejelentkezés",'

    + '"tab_szolgaltato_login_l_jelszoemlekezteto":"Elfelejtett jelszó",'

    + '"embedded_koszono_fejlec":"Sikeres foglalás!",'
    + '"embedded_koszono_cim":"Köszönjük foglalásod!",'
    + '"embedded_koszono_szoveg":"A visszaigazolást e-mailben elküldtük. Kérjük, látogasd meg a postafiókod!",'


    + '"popover_idoszelektor_l_beallitas":"Beállítás",'

    + '"popover_info_l_bezar":"Bezár",'

    + '"INCORECT_DATE":"Hibás dátum!",'
    + '"FB_SUCCESS":"Sikeres leválasztás!",'
    + '"FB_ERR_NOTEXISTS":"A facebook azonosító nem létezik!",'
    + '"FB_ERR_NODB":"Adatbázis kapcsolat hiba!",'
    + '"UNKNOWN_HASH":"Ismeretlen felhasználó!",'
    + '"MISSING_ASZF":"A szabályzat nem lett elfogadva!",'
    + '"embedded_newpassword_fejlec":"Új jelszó beállítása.",'
    + '"embedded_newpassword_beallitva":"Új jelszó beállítva.",'

    + '"embedded_newpassword_fejlec":"Új jelszó beállítása.",'
    + '"embedded_newpassword_beallitva":"Új jelszó beállítva.",'

    + '"embedded_newpasswordrequest_fejlec":"Új jelszó igénylése.",'
    + '"embedded_newpasswordrequest_postazva":"A jelszómódosításhoz szükséges üzenetet elküdtük. Kérjük, látogasd meg a postafiókod!",'

    + '"popover_naptarmodosit":"Amennyiben a naptár bejegyzést módosítja az eredeti bejegyzést most törli a rendszer. Valóban ezt akarja?",'


    + '"popover_figy_l_bezar":"Bezár.",'
    + '"popover_figy_szoveg":"Amíg a munkaidőt és legalább egy szolgáltatást nem vett fel addig nem használhatja a naptárat!",'


    + '"tab_szolgaltato_beallitas_szolgaltatasok_add":"szolgaltatasok_add info magyar"}';

  private lang_en: any;

  public langobject: any;
  public deafultTimeFrame: any;

  public naplo_valasztottszolgaltatas_szolgid: String;
  public naplo_valasztottszolgaltatas_szolgneve: String;
  public naplo_valasztottszolgaltatas_idopont_datum: String;
  public naplo_valasztottszolgaltatas_idopontig_datum: String;
  public naplo_valasztottszolgaltatas_szolgszine: String;
  public naplo_valasztottszolgaltatas_szolghossz: String;

  public naptarmodositas_user_id: String;
  public naptarmodositas_name: String;
  public naptarmodositas_email: String;
  public naptarmodositas_phone: String;

  public most: Date = new Date();
  public honapnevek: Array<string> = ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"];
  public honapnevek_rovid: Array<string> = ["Jan.", "Feb.", "Márc.", "Ápr.", "Máj.", "Jún.", "Júl.", "Aug.", "Szep.", "Okt.", "Nov.", "Dec."];

  public idopont_hasonlito: string = '';        // A naptarhoz kell!!!
  public off_hash: string = '';                 // Offline uzemmodban az user hash
  public fbauthid: string = '';                 // Offline uzemmodban az user fbauth id

  // FB Og meta - start
  public og_url: string = '';
  public og_title: string = '';
  public og_description: string = '';
  public og_image: string = '';
  // FB Og meta - stop


  public idoszakok: Array<ModelIndexErtek> = [new ModelIndexErtek('7', '1 hét'),
  new ModelIndexErtek('14', '2 hét'),
  new ModelIndexErtek('21', '3 hét'),
  new ModelIndexErtek('28', '4 hét'),
  new ModelIndexErtek('60', '2 hónap'),
  new ModelIndexErtek('120', '4 hónap'),
  new ModelIndexErtek('180', '6 hónap'),
  new ModelIndexErtek('240', '8 hónap'),
  new ModelIndexErtek('365', '12 hónap')];

  public foglalaslegkesobb: Array<ModelIndexErtek> = [new ModelIndexErtek('2', '2 óra'),
  new ModelIndexErtek('4', '4 óra'),
  new ModelIndexErtek('6', '6 óra'),
  new ModelIndexErtek('8', '8 óra'),
  new ModelIndexErtek('16', '16 óra'),
  new ModelIndexErtek('24', '24 óra'),
  new ModelIndexErtek('48', '48 óra'),
  new ModelIndexErtek('72', '72 óra')];
  /*
 1-2-3-4 hét, 2-4-6-8-12 hónap
Az alsó esetében meg 2, 4, 6, 8, 16, 24, 48, 72 óra
*/
  constructor(private helper: JwtHelperService, public navCtrl: NavController, private callNumber: CallNumber, private http: HttpClient, public plt: Platform) {
    //idoszakok[] = new ModelIndexErtek();
    this.lang_en = this.lang_hu;
    this.betolt_lang();
  }

  loginhelper() {
    this.user = this.helper.decodeToken(this.token);
    //console.log("HELPER START");
    //console.log(this.user);
    //console.log("HELPER STOP");
  }


  post_alapertelmezettidoegyseg() {
    var valasz: any;
    this.http.get(this.serviceurl + 'static/time', this.httpauth_header_angular())
      .subscribe(data => {
        console.log("valasz alapertelmezettidoegyseg");
        console.log(data);
        valasz = data;
        //valasz = JSON.parse(data.data);
        console.log("alapidovalasz" + valasz["deafultTimeFrame"]);
        this.deafultTimeFrame = valasz["deafultTimeFrame"];
        this.nincsbeallitvamunkaido = valasz["nincsbeallitvamunkaido"];
        this.nincsbeallitvaszolgaltatas = valasz["nincsbeallitvaszolgaltatas"];

        if (this.nincsbeallitvamunkaido == 1) {
          this.navCtrl.navigateRoot('tab_szolgaltato_beallitas_munkaido');
        } else if (this.nincsbeallitvaszolgaltatas == 1) {
          this.navCtrl.navigateRoot('tab_szolgaltato_beallitas_szolgaltatasok');
        } else {
          this.navCtrl.navigateRoot('tab_szolgaltato_naptar');
        }


      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });
  }

  httpauthheader() {
    let header = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token }
    return header;
  }

  httpauth_header_angular() {
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    let config = { headers: header };
    return config;
  }

  httpauth_header_angular_emb() {
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.tokenemb
    });
    let config = { headers: header };
    return config;
  }

  httpauth_header_angular_png() {
    let header = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': 'Bearer ' + this.token
    });
    let config = { headers: header };
    return config;
  }


  tarcsaz(szam: string) {
    this.callNumber.callNumber(szam, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }


  betolt_nyelv() {

    this.http.get(this.serviceurl + 'company/settings/lang', this.httpauth_header_angular())
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.lang = data["lang"];
        console.log("LANG" + this.lang);
        this.betolt_lang();
        //this.sorok = data;
      }, err => {
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
      });
  }


  betolt_lang() {
    if (this.lang == 'en') {
      this.langobject = JSON.parse(this.lang_en);
    } else {
      this.langobject = JSON.parse(this.lang_hu);
    }
  }

  kovetkezomunkanap() {
    this.http.get(this.serviceurl + 'kovetkezomunkanap', this.httpauth_header_angular())
      .subscribe(data => {
        let valasz = data;
        console.log('kovetkezomunkanap');
        console.log(data);
      }, err => {
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
      });
  }

  vanefoglalas() {
    this.http.get(this.serviceurl + 'vanefoglalas', this.httpauth_header_angular())
      .subscribe(data => {
        console.log('vanefoglalas');
        let valasz = data;
        console.log(data);
      }, err => {
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
      });
  }

}

class ModelIndexErtek {
  public index: string;
  public ertek: string;
  constructor(_index: string, _ertek: string) {
    this.index = _index;
    this.ertek = _ertek;
  }
}
