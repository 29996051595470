import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SettingsService } from '../settings.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-embedded-newpassword',
  templateUrl: './embedded-newpassword.page.html',
  styleUrls: ['./embedded-newpassword.page.scss'],
})
export class EmbeddedNewpasswordPage implements OnInit {

  form_jelszoigenyles: FormGroup;
  hiba_password: any = '';
  elpostazva: any = '';
  hash: any;
  ismeretlenhash: any = '';
  constructor(private http: HttpClient, private route: ActivatedRoute, public navCtrl: NavController, public settings: SettingsService, private fb: FormBuilder, public _sanitizer: DomSanitizer) {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.form_jelszoigenyles = this.fb.group({
      password: ['']
    });

    this.letezikahash();
  }

  letezikahash() {
    let postData = {
      "hash": this.hash,
    }

    let queryString = Object.keys(postData).map(key => key + '=' + postData[key]).join('&');
    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'authemb/newpass/?' + queryString);
    this.http.get(this.settings.serviceurl + 'authemb/newpass/?' + queryString, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        //this.navCtrl.navigateRoot('embedded-koszono');
      }, err => {
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
        this.ismeretlenhash = '1';
      });
  }


  ujjelszoigenyles() {
    let postData = {
      "password": this.form_jelszoigenyles.value["password"],
      "hash": this.hash,
    }

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'authemb/newpass');
    this.http.post(this.settings.serviceurl + 'authemb/newpass', postData, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.hiba_password = '';
        this.elpostazva = 1;
        //this.navCtrl.navigateRoot('embedded-koszono');
      }, err => {
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
        let valasz = err.error;
        for (var i = 0; i < valasz.length; i++) {
          if (valasz[i]['field'] == 'password') {
            this.hiba_password = this._sanitizer.bypassSecurityTrustHtml('<ion-item><ion-label>' + this.settings.langobject[valasz[i]['error']] + '</ion-label></ion-item>');
          } else if (valasz[i]['field'] == 'db') {
            this.hiba_password = this._sanitizer.bypassSecurityTrustHtml('<ion-item><ion-label>' + this.settings.langobject[valasz[i]['error']] + '</ion-label></ion-item>');
          }
        }
      });
  }

  ngOnInit() {
  }

}
