import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EmbeddedNaptarPageRoutingModule } from './embedded-naptar-routing.module';

import { EmbeddedNaptarPage } from './embedded-naptar.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EmbeddedNaptarPageRoutingModule
  ],
  declarations: [EmbeddedNaptarPage]
})
export class EmbeddedNaptarPageModule {}
