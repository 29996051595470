import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ExternalMerchantNewpasswordPageRoutingModule } from './external-merchant-newpassword-routing.module';

import { ExternalMerchantNewpasswordPage } from './external-merchant-newpassword.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ExternalMerchantNewpasswordPageRoutingModule
  ],
  declarations: [ExternalMerchantNewpasswordPage]
})
export class ExternalMerchantNewpasswordPageModule {}
