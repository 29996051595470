import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-figyelmeztetujfelh',
  templateUrl: './popover-figyelmeztetujfelh.component.html',
  styleUrls: ['./popover-figyelmeztetujfelh.component.scss'],
})
export class PopoverFigyelmeztetujfelhComponent implements OnInit {
  popoverCtrl: PopoverController;
  navParams: NavParams;
  settings: SettingsService;
  constructor(public _settings: SettingsService, public params: NavParams) { 
    this.settings = _settings;
    this.navParams = params;
    this.popoverCtrl = params.get('popoverCtrl');
  }
  click_bezar() {
    this.popoverCtrl.dismiss();
  }
  ngOnInit() {}

}
