import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { NavParams, PopoverController } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';

@Component({
  selector: 'app-popover_info',
  templateUrl: './popover_info.component.html',
  styleUrls: ['./popover_info.component.scss'],
})

export class PopoverInfoComponent implements OnInit {
/*
  @ViewChild('ido_nyitas') _ido_nyitas;
  @ViewChild('ido_zaras') _ido_zaras;
*/
  popoverCtrl: PopoverController;
  navParams: NavParams;
  settings: SettingsService;
  info: any;
  //exp_nyitas: string;
  //exp_zaras: string;
  //idoszelektor: String;
  constructor(private fb: FormBuilder, public _settings: SettingsService, public params: NavParams, private http: HTTP,) {
    //var idoszelektor = document.getElementById('idoszelektor');
    this.settings = _settings;
    this.navParams = params;
    this.popoverCtrl = params.get('popoverCtrl');
    this.info = params.get('info');
  }

  click_bezar() {
    this.popoverCtrl.dismiss();
  }

  ngOnInit() {
  }
}
