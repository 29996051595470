import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { SettingsService } from '../settings.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-embedded-passwordrequest',
  templateUrl: './embedded-passwordrequest.page.html',
  styleUrls: ['./embedded-passwordrequest.page.scss'],
})
export class EmbeddedPasswordrequestPage implements OnInit {

  form_jelszoigenyles: FormGroup;
  hiba_email: any = '';
  elpostazva: any = '';
  constructor(private http: HttpClient, public navCtrl: NavController, public settings: SettingsService, private fb: FormBuilder, public _sanitizer: DomSanitizer) {
    this.form_jelszoigenyles = this.fb.group({
      email: ['']
    })

   }

  ujjelszoigenyles() {
    let postData = {
      "email": this.form_jelszoigenyles.value["email"],
    }

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };    

    console.log(this.settings.serviceurl + 'authemb/passrequest');
    this.http.post(this.settings.serviceurl + 'authemb/passrequest', postData, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.hiba_email='';
        this.elpostazva=1;
        //this.navCtrl.navigateRoot('embedded-koszono');
      }, err => {
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
        let valasz = err.error;
        for (var i = 0; i < valasz.length; i++) {
          if (valasz[i]['field'] == 'email') {
            this.hiba_email = this._sanitizer.bypassSecurityTrustHtml('<ion-item lines="none" nopadding nomargin><ion-text>' + this.settings.langobject[valasz[i]['error']] + '</ion-text></ion-item>');
          } else if (valasz[i]['field'] == 'db') {
            this.hiba_email = this._sanitizer.bypassSecurityTrustHtml('<ion-item lines="none" nopadding nomargin><ion-text>' + this.settings.langobject[valasz[i]['error']] + '</ion-text></ion-item>');
          }
        }
      });
  }

  ngOnInit() {
  }

}
