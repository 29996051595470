import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP } from '@ionic-native/http/ngx';
import { PopoverIdoszelektorComponent } from './popover_idoszelektor/popover_idoszelektor.component';
import { PopoverTorlodazusertComponent } from './popover_torlodazusert/popover_torlodazusert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Storage, IonicStorageModule } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { PopoverUseradatmodositasComponent } from './popover_useradatmodositas/popover_useradatmodositas.component';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { PopoverInfoComponent } from './popover_info/popover_info.component';
import { Facebook } from '@ionic-native/facebook/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HttpClientModule } from '@angular/common/http';
import { PopoverUseradatOffComponent } from './popover-useradat-off/popover-useradat-off.component';
import { PopoverFigyelmeztetujfelhComponent } from './popover-figyelmeztetujfelh/popover-figyelmeztetujfelh.component';
import { PopoverSzabadsagTorlodComponent } from './popover-szabadsag-torlod/popover-szabadsag-torlod.component';
import { FileUploader } from 'ng2-file-upload';
import { PopoverNaptarmodositasComponent } from './popover-naptarmodositas/popover-naptarmodositas.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SocialLoginModule, AuthServiceConfig, LoginOpt } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";

const googleLoginOptions: LoginOpt = {
  scope: 'profile email'
};


/*
Your Client ID

840705371737-7jfm26pv2en5nf6dcs4tod09gi0ncbit.apps.googleusercontent.com

Your Client Secret

5LzW8Df4L49uoeX3TSt3EktC

Ezt is hozzáadtam:
*/

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("840705371737-7jfm26pv2en5nf6dcs4tod09gi0ncbit.apps.googleusercontent.com", googleLoginOptions)
  }
]);
 
export function provideConfig() {
  return config;
}

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('access_token');
    },
    whitelistedDomains: ['localhost:5000']
  }
}
@NgModule({
  declarations: [AppComponent, PopoverNaptarmodositasComponent, PopoverSzabadsagTorlodComponent, PopoverFigyelmeztetujfelhComponent, PopoverUseradatOffComponent, PopoverInfoComponent, PopoverIdoszelektorComponent, PopoverTorlodazusertComponent, PopoverUseradatmodositasComponent],
  entryComponents: [PopoverNaptarmodositasComponent, PopoverSzabadsagTorlodComponent, PopoverUseradatOffComponent, PopoverFigyelmeztetujfelhComponent, PopoverInfoComponent, PopoverIdoszelektorComponent, PopoverTorlodazusertComponent, PopoverUseradatmodositasComponent],
  imports: [ImageCropperModule, SocialLoginModule, BrowserModule, HttpClientModule, IonicModule.forRoot(), AppRoutingModule, FormsModule, ReactiveFormsModule, IonicStorageModule.forRoot(), JwtModule.forRoot({
    jwtOptionsProvider: {
      provide: JWT_OPTIONS,
      useFactory: jwtOptionsFactory,
      deps: [Storage],
    }
  }), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    CallNumber,
    EmailComposer,
    HTTP,
    StatusBar,
    SplashScreen,
    Facebook,
    NativeStorage,
    Deeplinks,
    Keyboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FileUploader, useClass: FileUploader, deps: [] },
    { provide: AuthServiceConfig, useFactory: provideConfig}    
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
