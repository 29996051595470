import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';
import { PopoverController, NavParams, NavController } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';

@Component({
  selector: 'app-popover-naptarmodositas',
  templateUrl: './popover-naptarmodositas.component.html',
  styleUrls: ['./popover-naptarmodositas.component.scss'],
})
export class PopoverNaptarmodositasComponent implements OnInit {
  popoverCtrl: PopoverController;
  navParams: NavParams;
  id: String;
  constructor( public settings: SettingsService, public params: NavParams, private http: HTTP, public navCtrl: NavController) { 
    this.navParams = params;
    this.popoverCtrl = params.get('popoverCtrl');
    this.id = params.get('id');
    console.log("AZEMAIL: "+this.settings.naptarmodositas_email);
  }


  click_megsem() {
    this.popoverCtrl.dismiss();
  }
  click_modosit() {

    this.popoverCtrl.dismiss();

    // A hely felszabaditasa - start
    let postData = {
      "id": this.id,
      "uid": 0
    }      
    this.http.setDataSerializer('json');
    console.log(this.settings.serviceurl + 'calendar/torolfoglalas');
    console.log(postData);
    this.http.post(this.settings.serviceurl + 'calendar/torolfoglalas', postData, this.settings.httpauthheader())
      .then(data => {
        console.log(data.data);
        //valasz = JSON.parse(data.data);
        //this.settings.token=valasz["token"];
        //this.popoverCtrl.dismiss();
        //this.navCtrl.navigateForward('tab_szolgaltato_naptar');
        //this.ngOnInit();
        //this.ngOnInit();
      }).catch(error => {

        //valasz = JSON.parse(error.error);
        //console.log("valasz" + valasz);
        console.log(error.status);
        console.log(error.error); // error message as string
        console.log(error.headers);
      });    
    // A hely felszabaditasa - stop

    // Uj foglalas inditasa
    this.navCtrl.navigateRoot('tab_szolgaltato_naptar_szolgaltatas');    
  }



  ngOnInit() {}

}
