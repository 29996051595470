import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },

  { path: 'tab_szolgaltato_login', loadChildren: './tab_szolgaltato_login/tab_szolgaltato_login.module#Tab_szolgaltato_loginPageModule' },
  { path: 'tab_szolgaltato_reg', loadChildren: './tab_szolgaltato_reg/tab_szolgaltato_reg.module#Tab_szolgaltato_regPageModule' },
  { path: 'tab_szolgaltato_naptar', loadChildren: './tab_szolgaltato_naptar/tab_szolgaltato_naptar.module#Tab_szolgaltato_naptarPageModule' },
  { path: 'tab_szolgaltato_sugo', loadChildren: './tab_szolgaltato_sugo/tab_szolgaltato_sugo.module#Tab_szolgaltato_sugoPageModule' },
  { path: 'tab_szolgaltato_sugo/:keres', loadChildren: './tab_szolgaltato_sugo/tab_szolgaltato_sugo.module#Tab_szolgaltato_sugoPageModule' },
  { path: 'tab_szolgaltato_beallitas', loadChildren: './tab_szolgaltato_beallitas/tab_szolgaltato_beallitas.module#Tab_szolgaltato_beallitasPageModule' },
  { path: 'tab_szolgaltato_kontaktok', loadChildren: './tab_szolgaltato_kontaktok/tab_szolgaltato_kontaktok.module#Tab_szolgaltato_kontaktokPageModule' },
  { path: 'tab_szolgaltato_ertesitesek', loadChildren: './tab_szolgaltato_ertesitesek/tab_szolgaltato_ertesitesek.module#Tab_szolgaltato_ertesitesekPageModule' },
  { path: 'tab_szolgaltato_kontaktok_egyuser', loadChildren: './tab_szolgaltato_kontaktok_egyuser/tab_szolgaltato_kontaktok_egyuser.module#Tab_szolgaltato_kontaktok_egyuserPageModule' },
  { path: 'tab_szolgaltato_kontaktok_egyuser/:id', loadChildren: './tab_szolgaltato_kontaktok_egyuser/tab_szolgaltato_kontaktok_egyuser.module#Tab_szolgaltato_kontaktok_egyuserPageModule' },
  { path: 'tab_szolgaltato_naptar/:oldaltipus', loadChildren: './tab_szolgaltato_naptar/tab_szolgaltato_naptar.module#Tab_szolgaltato_naptarPageModule' },
  { path: 'tab_szolgaltato_naptar/:szolgid/:oldaltipus', loadChildren: './tab_szolgaltato_naptar/tab_szolgaltato_naptar.module#Tab_szolgaltato_naptarPageModule' },
  { path: 'tab_szolgaltato_naptar/:szolgid/:oldaltipus/:userid', loadChildren: './tab_szolgaltato_naptar/tab_szolgaltato_naptar.module#Tab_szolgaltato_naptarPageModule' },
  { path: 'tab_szolgaltato_naptar/:szolgid/:oldaltipus/:userid/:szolgrekid', loadChildren: './tab_szolgaltato_naptar/tab_szolgaltato_naptar.module#Tab_szolgaltato_naptarPageModule' },
  { path: 'tab_szolgaltato_naptar_szolgaltatas', loadChildren: './tab_szolgaltato_naptar_szolgaltatas/tab_szolgaltato_naptar_szolgaltatas.module#Tab_szolgaltato_naptar_szolgaltatasPageModule' },
  { path: 'tab_szolgaltato_naptar_szolgaltatas/:userid', loadChildren: './tab_szolgaltato_naptar_szolgaltatas/tab_szolgaltato_naptar_szolgaltatas.module#Tab_szolgaltato_naptar_szolgaltatasPageModule' },
  { path: 'tab_szolgaltato_naptar_szolgaltatas/:userid/:szolgrekid', loadChildren: './tab_szolgaltato_naptar_szolgaltatas/tab_szolgaltato_naptar_szolgaltatas.module#Tab_szolgaltato_naptar_szolgaltatasPageModule' },
  { path: 'tab_szolgaltato_beallitas_szolgaltatasok_add', loadChildren: './tab_szolgaltato_beallitas_szolgaltatasok_add/tab_szolgaltato_beallitas_szolgaltatasok_add.module#Tab_szolgaltato_beallitas_szolgaltatasok_addPageModule' },
  { path: 'tab_szolgaltato_beallitas_szolgaltatasok_add/:szolgid', loadChildren: './tab_szolgaltato_beallitas_szolgaltatasok_add/tab_szolgaltato_beallitas_szolgaltatasok_add.module#Tab_szolgaltato_beallitas_szolgaltatasok_addPageModule' },
  { path: 'tab_szolgaltato_beallitas_adataim', loadChildren: './tab_szolgaltato_beallitas_adataim/tab_szolgaltato_beallitas_adataim.module#Tab_szolgaltato_beallitas_adataimPageModule' },
  { path: 'tab_szolgaltato_beallitas_munkaido', loadChildren: './tab_szolgaltato_beallitas_munkaido/tab_szolgaltato_beallitas_munkaido.module#Tab_szolgaltato_beallitas_munkaidoPageModule' },
  { path: 'tab_szolgaltato_beallitas_szolgaltatasok', loadChildren: './tab_szolgaltato_beallitas_szolgaltatasok/tab_szolgaltato_beallitas_szolgaltatasok.module#Tab_szolgaltato_beallitas_szolgaltatasokPageModule' },
  { path: 'tab_szolgaltato_beallitas_szabadsag', loadChildren: './tab_szolgaltato_beallitas_szabadsag/tab_szolgaltato_beallitas_szabadsag.module#Tab_szolgaltato_beallitas_szabadsagPageModule' },
  { path: 'tab_szolgaltato_beallitas_ertesito_uzenet', loadChildren: './tab_szolgaltato_beallitas_ertesito_uzenet/tab_szolgaltato_beallitas_ertesito_uzenet.module#Tab_szolgaltato_beallitas_ertesito_uzenetPageModule' },
  { path: 'embedded', loadChildren: './embedded/embedded.module#EmbeddedPageModule' },
  { path: 'external-moddel', loadChildren: './external-moddel/external-moddel.module#ExternalModdelPageModule' },
  { path: 'external-moddel/:hash', loadChildren: './external-moddel/external-moddel.module#ExternalModdelPageModule' },
  { path: 'tab-szolgaltato-beallitas-nyelv', loadChildren: './tab-szolgaltato-beallitas-nyelv/tab-szolgaltato-beallitas-nyelv.module#TabSzolgaltatoBeallitasNyelvPageModule' },
  { path: 'tab-szolgaltato-beallitas-marketing', loadChildren: './tab-szolgaltato-beallitas-marketing/tab-szolgaltato-beallitas-marketing.module#TabSzolgaltatoBeallitasMarketingPageModule' },
  { path: 'external-naptar', loadChildren: './external-naptar/external-naptar.module#ExternalNaptarPageModule' },
  { path: 'external-naptar-szolgaltatas', loadChildren: './external-naptar-szolgaltatas/external-naptar-szolgaltatas.module#ExternalNaptarSzolgaltatasPageModule' },
  { path: 'tab-szolgaltato-beallitas-fblekapcs', loadChildren: './tab-szolgaltato-beallitas-fblekapcs/tab-szolgaltato-beallitas-fblekapcs.module#TabSzolgaltatoBeallitasFblekapcsPageModule' },
  { path: 'embedded-naptar', loadChildren: './embedded-naptar/embedded-naptar.module#EmbeddedNaptarPageModule' },
  { path: 'embedded-naptar-szolgaltatas', loadChildren: './embedded-naptar-szolgaltatas/embedded-naptar-szolgaltatas.module#EmbeddedNaptarSzolgaltatasPageModule' },
  { path: 'embedded-kliensinfo', loadChildren: './embedded-kliensinfo/embedded-kliensinfo.module#EmbeddedKliensinfoPageModule' },
  { path: 'embedded-koszono', loadChildren: './embedded-koszono/embedded-koszono.module#EmbeddedKoszonoPageModule' },
  { path: 'external-merchant-passwordrequest', loadChildren: './external-merchant-passwordrequest/external-merchant-passwordrequest.module#ExternalMerchantPasswordrequestPageModule' },
  { path: 'external-merchant-newpassword', loadChildren: './external-merchant-newpassword/external-merchant-newpassword.module#ExternalMerchantNewpasswordPageModule' },
  { path: 'external-merchant-newpassword/:hash', loadChildren: './external-merchant-newpassword/external-merchant-newpassword.module#ExternalMerchantNewpasswordPageModule' },
  { path: 'embedded-passwordrequest', loadChildren: './embedded-passwordrequest/embedded-passwordrequest.module#EmbeddedPasswordrequestPageModule' },
  { path: 'embedded-newpassword', loadChildren: './embedded-newpassword/embedded-newpassword.module#EmbeddedNewpasswordPageModule' },
  { path: 'embedded-newpassword/:hash', loadChildren: './embedded-newpassword/embedded-newpassword.module#EmbeddedNewpasswordPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }