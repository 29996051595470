import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, FormControl} from '@angular/forms';
import {SettingsService} from '../settings.service';
import {NavParams, PopoverController} from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-popover-useradat-off',
  templateUrl: './popover-useradat-off.component.html',
  styleUrls: ['./popover-useradat-off.component.scss'],
})
export class PopoverUseradatOffComponent implements OnInit {

    form_kontaktok_egyuser: FormGroup;

    popoverCtrl: PopoverController;
    navParams: NavParams;

    hiba_name: string = '';
    hiba_phone: string = '';
    hiba_email: string = '';

    name: string = '';
    phone: string = '';
    email: string = '';


    utolsovalasztottname: any = '';
    megjelenhetautocomp: any = false;

    input: string = '';

    sikeresfoglalas: number = 0;

    sorok: any = [];
    //_sorok: string = '{"name":"sadfasdfas","phone":"1545154545454","email":"lklklokd@aaaas.hu","elojegyzesek":[{"szolgneve":"Hajvagas","idopont":"2019-01-01","valasztottszin":"9a7d0a"}]}';

    naplo_valasztottszolgaltatas_idopont_datum: any;

    //exp_nyitas: string;
    //exp_zaras: string;
    //idoszelektor: String;
    constructor(private fb: FormBuilder, public settings: SettingsService, public params: NavParams, private http: HttpClient) {


        this.form_kontaktok_egyuser = new FormGroup({
            email: new FormControl(),
            phone: new FormControl(),
            name: new FormControl(),
        });

        //var idoszelektor = document.getElementById('idoszelektor');

        this.naplo_valasztottszolgaltatas_idopont_datum = this.settings.naplo_valasztottszolgaltatas_idopont_datum;
        this.navParams = params;
        this.popoverCtrl = params.get('popoverCtrl');

        //this.sorok = JSON.parse(this._sorok);
        //console.log("A TOMB:");
        //console.log(this.sorok.email);

        this.get_form_kontaktok_egyuser();
/*
        this.form_kontaktok_egyuser = this.fb.group({
            email: this.sorok.email,
            phone: this.sorok.phone,
            name: this.sorok.name,
        });
        */

        console.log('this.hash: ' + this.settings.off_hash);
    }

    datummegjelenit() {
        var out: string = '';
        if (this.settings.naplo_valasztottszolgaltatas_idopont_datum != null) {
            out += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(0, 4) + ' ';
            if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '01') {
                out += 'Január';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '02') {
                out += 'Február';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '03') {
                out += 'Március';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '04') {
                out += 'Április';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '05') {
                out += 'Május';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '06') {
                out += 'Június';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '07') {
                out += 'Július';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '08') {
                out += 'Augusztus';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '09') {
                out += 'Szeptember';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '10') {
                out += 'Október';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '11') {
                out += 'November';
            } else if (this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(5, 2) == '12') {
                out += 'December';
            }
            out += ' ';
            out += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(8, 2);

            out += ' ';
            out += this.settings.naplo_valasztottszolgaltatas_idopont_datum.substr(11, 5);
            //out += '-';
            //out += this.settings.naplo_valasztottszolgaltatas_idopontig_datum.substr(11, 5);

            console.log('POPOVER_OUT: ' + out);

            return out;
        } else {
            return false;
        }
    }

    get_form_kontaktok_egyuser() {

      let postData = {
        "hash": this.settings.off_hash,
      }      

      let header = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      let config = { headers: header };
      this.http.post(this.settings.serviceurl + 'company/getuseroff', postData, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.sorok = data;

        // console.log(this.sorok.email);

        this.email = this.sorok.email;
        this.phone = this.sorok.phone;
        this.name = this.sorok.name;        
        // console.log(this.email);
      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });      
    }


    post_form_kontaktok_egyuser() {
        var valasz: string | { [x: string]: string; }[];
        //var navCtrl: NavController;
        //var settings: SettingsService;
        let postData = {
                'email': this.form_kontaktok_egyuser.value['email'],
                'phone': this.form_kontaktok_egyuser.value['phone'],
                'name': this.form_kontaktok_egyuser.value['name'],
                'hash': this.settings.off_hash,
                'dateTime': (this.settings.naplo_valasztottszolgaltatas_idopont_datum).substr(0, (this.settings.naplo_valasztottszolgaltatas_idopont_datum).length - 3),
                'serviceId': this.settings.naplo_valasztottszolgaltatas_szolgid,
            };

        //console.log('post_form_kontaktok_egyuser '+JSON.stringify(this.settings.user));
        console.log('post_form_kontaktok_egyuser ' + JSON.stringify(postData));

        let header = new HttpHeaders({
          'Content-Type': 'application/json'
        });
  
        let config = { headers: header };

        this.http.post(this.settings.serviceurl + 'bookoff', postData, config)
        .subscribe(data => {
          console.log("valasz");
          console.log(data);
                //console.log(data.data);
                this.popoverCtrl.dismiss();
/*

                //valasz = JSON.parse(data.data);
                if (this.naplo_valasztottszolgaltatas_idopont_datum != '') {
                    this.settings.naplo_valasztottszolgaltatas_idopont_datum = '';
                    this.settings.naplo_valasztottszolgaltatas_szolgid = '';
                    this.settings.naplo_valasztottszolgaltatas_szolgneve = '';
                    this.sikeresfoglalas = 1;
                }
*/

        }, err => {
          console.log('HError: ' + err.headers);
          console.log('Error: ' + err.error);
        });
    }
  ngOnInit() {}
}
