import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ExternalMerchantPasswordrequestPageRoutingModule } from './external-merchant-passwordrequest-routing.module';

import { ExternalMerchantPasswordrequestPage } from './external-merchant-passwordrequest.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, 
    IonicModule,
    ExternalMerchantPasswordrequestPageRoutingModule
  ],
  declarations: [ExternalMerchantPasswordrequestPage]
})
export class ExternalMerchantPasswordrequestPageModule {}
