import {Component} from '@angular/core';
import {SettingsService} from '../settings.service';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.scss'],
})
export class HomePage {
    serviceurl: string = '';

    constructor(public settings: SettingsService, private http: HttpClient, public navCtrl: NavController) {
        this.serviceurl = settings.serviceurl;
        console.log('Token:' + settings.token);
        this.isembedded();
    }

    public isembedded() {
        let header = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let config = {headers: header};
        this.http.get(this.settings.serviceurl + 'company/embedded', config)
            .subscribe(data => {
                console.log('isembedded valasz');
                console.log(data);
                if (data['companyid'] == false) {
                    this.navCtrl.navigateRoot('home');
                } else {
                    this.navCtrl.navigateRoot('embedded');
                }
            }, err => {
                /*
                console.log('HError: ' + err.headers);
                console.log('Error: ' + err.error);
                */
            });
    }

    open_tab_szolgaltato_login() {
        console.log('MMMMM');
        this.navCtrl.navigateRoot('tab_szolgaltato_login');
      }
    
      open_tab_szolgaltato_reg() {
        this.navCtrl.navigateRoot('tab_szolgaltato_reg');
      }

}
