import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';
import { NavController, LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Facebook } from '@ionic-native/facebook/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Router } from '@angular/router';
import { DomSanitizer, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-embedded',
  templateUrl: './embedded.page.html',
  styleUrls: ['./embedded.page.scss'],
})
export class EmbeddedPage implements OnInit {
  form_szolglogin: FormGroup;
  serviceurl: string = '';
  hiba_login: any = '';
  company_name: string;
  cim: string;
  phone: string;
  constructor(private meta: Meta, private http: HttpClient, private fb: FormBuilder, public settings: SettingsService, public navCtrl: NavController, public facebook: Facebook, public nativeStorage: NativeStorage, public loadingController: LoadingController, private router: Router, public _sanitizer: DomSanitizer) {
    this.serviceurl = settings.serviceurl;
    this.form_szolglogin = this.fb.group({
      email: [''],
      password: ['']
    })
    this.existscompany();
  }

  ngOnInit() {
  }


  ujelojegyzes() {
    this.navCtrl.navigateRoot('embedded-naptar-szolgaltatas');
  }


  public existscompany() {

    var valasz: string | { [x: string]: string; }[];
    //var navCtrl: NavController;
    //var settings: SettingsService;

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let config = { headers: header };
    console.log(this.settings.serviceurl + 'company/embedded');
    this.http.get(this.settings.serviceurl + 'company/embedded', config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);

        this.settings.og_url = data['host'];
        this.settings.og_title = data['og_cim'];
        this.settings.og_description = data['og_szoveg'];
        this.settings.og_image = data['og_kep'];

        this.company_name = data['company_name'];
        this.cim = data['cim'];
        this.phone = data['phone'];


        this.meta.addTag({ name: 'og:url', content: this.settings.og_url });
        this.meta.addTag({ name: 'og:type', content: 'website' });
        this.meta.addTag({ name: 'og:title', content: this.settings.og_title });
        this.meta.addTag({ name: 'og:description', content: this.settings.og_description });
        this.meta.addTag({ name: 'og:image', content: this.settings.og_image });
        //this.sorok = data;
        /*
        this.companyname = data["companyname"];
        this.szolgneve = data["elojegyzesek"][0]["szolgneve"];
        this.idopont = data["elojegyzesek"][0]["idopont"];
        */
      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });
  }

  share_facebook() {
    console.log("og_url", this.settings.og_url);
    console.log("og_title", this.settings.og_title);
    console.log("og_description", this.settings.og_description);
    console.log("og_image", this.settings.og_image);
    this.facebook.showDialog({method: "share", href: this.settings.og_url, caption: this.settings.og_title, description: this.settings.og_description, picture: this.settings.og_image}).then(function (success) {
        console.log("success", success);
        // success
      }, function (error) {
        console.log("error", error);
        // error
      });
    /*
    this.socialSharing.share(this.settings.og_description, this.settings.og_title, this.settings.og_image, this.settings.og_url).then(() => {

    }).catch (e => {

    });
    this.socialSharing.shareViaFacebook(this.settings.og_description).then(() => {

    }).catch (e => {

    });
    */
  }


  post_form_szolglogin() {
    var valasz: string | { [x: string]: string; }[];
    //var navCtrl: NavController;
    //var settings: SettingsService;
    let postData = {
      "email": this.form_szolglogin.value["email"],
      "password": this.form_szolglogin.value["password"]
    }

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.serviceurl + 'authemb/login');
    this.http.post(this.serviceurl + 'authemb/login', postData, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.settings.tokenemb = data["token"];
        this.ujelojegyzes();
      }, err => {
        valasz = err.error;
        for (var i = 0; i < valasz.length; i++) {
          if (valasz[i]['field'] == 'email') {
            this.hiba_login = this._sanitizer.bypassSecurityTrustHtml('<ion-item><ion-label>' + this.settings.langobject[valasz[i]['error']] + '</ion-label></ion-item>');
          } else if (valasz[i]['field'] == 'password') {
            this.hiba_login = this._sanitizer.bypassSecurityTrustHtml('<ion-item><ion-label>' + this.settings.langobject[valasz[i]['error']] + '</ion-label></ion-item>');
          }
        }
        console.log('HError: ');
        console.log(err.headers);
        console.log('Error: ');
        console.log(err.error);
      });
  }


  async doFbLogin() {
    var valasz: string | { [x: string]: string; }[];
    const loading = await this.loadingController.create({
      message: 'Kérem várjon...'
    });
    this.presentLoading(loading);
    let permissions = new Array<string>();

    //the permissions your facebook app needs from the user
    permissions = ["public_profile", "email"];

    this.facebook.login(permissions)
      .then(response => {
        let userId = response.authResponse.accessToken;
        console.log(response.authResponse.accessToken);

        let postData = {
          "token": userId,
        }

        let header = new HttpHeaders({
          'Content-Type': 'application/json'
        });

        let config = { headers: header };

        console.log(this.serviceurl + 'authemb/facebook');
        this.http.post(this.serviceurl + 'authemb/facebook', postData, config)
          .subscribe(data => {
            console.log("valasz");
            console.log(data);
            loading.dismiss();
            this.settings.tokenemb = data["token"];
            this.ujelojegyzes();
          }, err => {
            console.log('HError: ');
            console.log(err.headers);
            console.log('Error: ');
            console.log(err.error);

            loading.dismiss();
            //console.log("valasz " + error.error);
            valasz = err.error;
            console.log("valasz2 " + valasz["error-key"]);
            this.hiba_login = this._sanitizer.bypassSecurityTrustHtml('<ion-item><ion-label>' + this.settings.langobject[valasz["error-key"]] + '</ion-label></ion-item>');
          });

      }, error => {
        console.log(error);
        loading.dismiss();
      });
  }

  async presentLoading(loading) {
    return await loading.present();
  }

  jelszoemlekezteto() {
    this.navCtrl.navigateRoot('embedded-passwordrequest');
  }


}
