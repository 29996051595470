import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';
import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-naptar-szolgaltatas',
  templateUrl: './external-naptar-szolgaltatas.page.html',
  styleUrls: ['./external-naptar-szolgaltatas.page.scss'],
})
export class ExternalNaptarSzolgaltatasPage implements OnInit {

  serviceurl: string = '';
  sorok: any;
  //_sorok: string = '[{"id":"4","szolgneve":"Birkanyiras","valasztottszin":"fad7a0"},{"id":"8","szolgneve":"Hajvagas","valasztottszin":"e59866"},{"id":"11","szolgneve":"Hajfestes","valasztottszin":"db7db0"}]';
  userid: string = '';
  szolgrekid: string = '';

  public naplo_valasztottszolgaltatas_szolgid: any;
  public naplo_valasztottszolgaltatas_szolgneve: any;
  //public naplo_valasztottszolgaltatas_idopont_datum: any;

  constructor(private http: HttpClient, public settings: SettingsService, public navCtrl: NavController, private route: ActivatedRoute) {
    /*
    this.serviceurl = settings.serviceurl;
    settings.naplo_valasztottszolgaltatas_szolgid = '';
    settings.naplo_valasztottszolgaltatas_szolgneve = '';
    this.userid = this.route.snapshot.paramMap.get('userid');
    this.szolgrekid = this.route.snapshot.paramMap.get('szolgrekid');
    */

    console.log("USERID " + settings.off_hash);
    //settings.naplo_valasztottszolgaltatas_idopont_datum = '';
    this.get_naptar_szolgaltatas();
  }

  szolgvalasztva(szolgid: string) {
    this.settings.naplo_valasztottszolgaltatas_szolgid = szolgid;
    console.log('szolgid ' + this.settings.naplo_valasztottszolgaltatas_szolgid);

    this.navCtrl.navigateRoot('external-naptar');
  }

  kisbetu(szoveg: string) {
    return szoveg.toLowerCase();
  }

  get_naptar_szolgaltatas() {

    let postData = {
      "hash": this.settings.off_hash,
    }

    let queryString = Object.keys(postData).map(key => key + '=' + postData[key]).join('&');

    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let config = { headers: header };

    console.log(this.settings.serviceurl + 'company/servicelistoff/?' + queryString);
    this.http.get(this.settings.serviceurl + 'company/servicelistoff/?' + queryString, config)
      .subscribe(data => {
        console.log("valasz");
        console.log(data);
        this.sorok = data;
        /*
        this.companyname = data["companyname"];
        this.szolgneve = data["elojegyzesek"][0]["szolgneve"];
        this.idopont = data["elojegyzesek"][0]["idopont"];
        */
      }, err => {
        console.log('HError: ' + err.headers);
        console.log('Error: ' + err.error);
      });
  }

  ngOnInit() {
  }

}
